import React from "react";
import PropTypes from "prop-types";
// ui
import { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
import Text from "@ui/text";
import Anchor from "@ui/anchor";
import Social, { SocialLink } from "@ui/social";
// utils
import { createColorByLocation } from "@utils/content.utils";
// styles
import { ContactInfoWrapper } from "./style";

const ContactUsInfo = ({ data }) => {
  return (
    <ContactInfoWrapper>
      <Container>
        <Row>
          <Col lg={4} mb="30px">
            <Heading as="h5" mb="20px">
              Address
            </Heading>
            <Text
              mb="7px"
              color="text"
              dangerouslySetInnerHTML={{ __html: data?.address }}
            />
          </Col>
          <Col lg={4} mb="30px">
            <Heading as="h5" mb="20px">
              Contact
            </Heading>
            <Anchor mb="7px" path={`mailto:${data?.email}`}>
              ​{data?.email}
            </Anchor>
            <Anchor
              mb="7px"
              path={`tel:+${data?.phone.replace(/ /g, "")}`}
              fontWeight={800}
              color="heading"
              display="block"
            >
              {data?.phone}
            </Anchor>
          </Col>
          <Col lg={4} mb="30px">
            <Heading as="h5" mb="20px">
              Follow us
            </Heading>
            <Social variant="outlined" shape="rounded" tooltip>
              {data?.socials.map((item) => (
                <SocialLink
                  key={item.id}
                  path={item.link_url}
                  backgroundColor={createColorByLocation()}
                >
                  <i className={item.icon_name}></i>
                </SocialLink>
              ))}
            </Social>
          </Col>
        </Row>
      </Container>
    </ContactInfoWrapper>
  );
};

ContactUsInfo.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    socials: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        link_url: PropTypes.string.isRequired,
        icon_name: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
};

export default ContactUsInfo;
