import React from "react";
import PropTypes from "prop-types";
// ui
import ScrollToTop from "@ui/scroll-to-top";
// components
import Transition from "@components/transition";
// containers
import Footer from "@containers/footer";
// assets
import "@assets/fonts/cerebrisans/cerebrisans.css";
import "@assets/fonts/font-awesome/font-awesome.css";
import "@assets/fonts/sofia-pro/sofia-pro.css";
import "@assets/css/bootstrap.css";
import { GlobalStyle } from "@assets/css/main-style";
// styles
import { ThemeProvider } from "@styled";
import { theme } from "@styled/theme";
import {Helmet} from "react-helmet";

const MainLayout = ({ children, location, withFooter }) => {
  return (
    <>
      <Helmet>
        <script type="text/javascript"
                src="https://api.roi-ai.app/WebActivityTracking/js?apiKey=aaeac608-ddea-4629-9982-4e126790aad6"
                crossOrigin/>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Transition location={location}>
          <div className="wrapper">
            <GlobalStyle />
            {children}
            {withFooter && <Footer />}
            <ScrollToTop />
          </div>
        </Transition>
      </ThemeProvider>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({}),
  withFooter: PropTypes.bool,
};

MainLayout.defaultProps = {
  withFooter: true,
};

export default MainLayout;
