import React from "react";
import PropTypes from "prop-types";
// ui
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@components/section-title";
// components
import ResourceBox from "@components/box-image-item";
import MottoText from "@components/motto";
// utils
import { createStrapiContentUrl } from "@utils/content.utils";
import { slugify } from "@utils";
// styles
import { SectionWrap } from "./style";

const Resources = ({ data, space }) => {
  return (
    <SectionWrap $space={space}>
      <Container px={[null, null, null, null, "35px"]}>
        {data?.title && data?.subtitle && (
          <SectionTitle title={data?.title} subtitle={data?.subtitle} />
        )}
        {data?.posts && (
          <Row gutters={{ xl: 70 }}>
            {data.posts?.map((item) => (
              <Col lg={4} md={6} className="box-item" key={item.id}>
                <ResourceBox
                  title={item.title}
                  desc={item.description}
                  image={{ src: createStrapiContentUrl(item.image?.url) }}
                  path={`/blog/${slugify(item.title)}`}
                />
              </Col>
            ))}
          </Row>
        )}
        {data?.link_text && data?.link_url && (
          <MottoText
            mt={["42px", null, "56px"]}
            text={null}
            linkText={data?.link_text}
            path={data?.link_url}
          />
        )}
      </Container>
    </SectionWrap>
  );
};

Resources.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    posts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
        }),
      })
    ),
    link_text: PropTypes.string,
    link_url: PropTypes.string,
  }),
  space: PropTypes.oneOf([1, 2]),
};

Resources.defaultProps = {
  space: 1,
};

export default Resources;
