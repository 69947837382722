import React from "react";
import PropTypes from "prop-types";
// containers
import SearchBarWidget from "@containers/search-bar-widget";
import IndustryJobsWidget from "@containers/industry-jobs-widget";
// components
import SectionTitle from "@components/section-title";
// ui
import { Container } from "@ui/wrapper";
// styles
import { SectionWrapper, TextWrapper } from "./style";

const IndustryJobs = ({ data }) => {
  return (
    <SectionWrapper id="industry-jobs">
      <Container>
        <SectionTitle
          mb={["30px", null, "40px"]}
          subtitle={data?.subtitle}
          title={data?.title}
        />
        <TextWrapper>
          <SearchBarWidget />
        </TextWrapper>
        <IndustryJobsWidget tag={data.tag} />
      </Container>
    </SectionWrapper>
  );
};

IndustryJobs.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
  }),
};

export default IndustryJobs;
