import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
// utils
import { createStrapiContentUrl } from "@utils/content.utils";
// types
import { ImagePropType } from "@utils/types";
// styles
import {
  SectionWrap,
  StyledLeftTitle,
  StyledRightTitle,
  StyledSubtitle,
  StyledFirstBackground,
  StyledTextWrapper,
  StyledSecondBackground,
} from "./style";

const HomeIntro = ({ leftData, rightData }) => {
  return (
    <SectionWrap>
      <Link to={leftData.link}>
        <StyledFirstBackground url={createStrapiContentUrl(leftData.image.url)}>
          <StyledTextWrapper id="text">
            <StyledSubtitle>{leftData.subtitle}</StyledSubtitle>
            <StyledLeftTitle
              dangerouslySetInnerHTML={{ __html: leftData.title }}
            />
          </StyledTextWrapper>
        </StyledFirstBackground>
      </Link>
      <Link to={rightData.link}>
        <StyledSecondBackground
          url={createStrapiContentUrl(rightData.image.url)}
        >
          <StyledTextWrapper id="text">
            <StyledSubtitle>{rightData.subtitle}</StyledSubtitle>
            <StyledRightTitle
              dangerouslySetInnerHTML={{ __html: rightData.title }}
            />
          </StyledTextWrapper>
        </StyledSecondBackground>
      </Link>
    </SectionWrap>
  );
};

HomeIntro.propTypes = {
  leftData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    image: ImagePropType.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
  rightData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    image: ImagePropType.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

export default HomeIntro;
