import React from "react";
import PropTypes from "prop-types";
// ui
import { Container } from "@ui/wrapper";
// components
import SectionTitle from "@components/section-title";
// styles
import { Root, StyledBox, Content } from "./style";

const LegalPageContainer = ({ data }) => {
  return (
    <Root>
      <Container>
        <StyledBox>
          <SectionTitle
            mb={["30px", null, "40px"]}
            subtitle=""
            title={data?.title}
          />
        </StyledBox>
        <Content dangerouslySetInnerHTML={{ __html: data.content }} />
      </Container>
    </Root>
  );
};

LegalPageContainer.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
};

export default LegalPageContainer;
