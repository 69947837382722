import React from "react";
import PropTypes from "prop-types";
// utils
import {
  createColorByLocation,
  createColorWithThemeByLocation,
} from "@utils/content.utils";
// styles
import {
  SectionWrap,
  StyledTitle,
  StyledSubtitle,
  StyleBackground,
  StyledTextWrapper,
} from "./style";

const IntroBlock = ({
  data,
  gradientType,
  backgroundColor,
  styles,
  bgHeight,
  isRounded,
}) => {
  // TODO: uncomment when we need a feature to change the background
  // state
  // const [activeImage, setActiveImage] = useState(data.images[0]);
  // ref
  // let index = useRef(0);
  // let bgTimeoutId = useRef();

  // const changeBackground = useCallback(() => {
  //   setActiveImage(data.images[index.current]);

  //   if (index.current == data.images.length - 1) index.current = 0;
  //   else index.current++;

  //   bgTimeoutId.current = setTimeout(changeBackground, 5000);
  // }, [index, data.images]);

  // useEffect(() => {
  // changeBackground();
  // return () => {
  //   clearTimeout(bgTimeoutId.current);
  // };
  // }, [changeBackground]);

  return (
    <SectionWrap backgroundColor={backgroundColor} $height={bgHeight}>
      <StyleBackground
        id="bg-image"
        // url={activeImage || data.images[0]}
        url={data.image}
        gradientType={gradientType}
        $styles={styles}
        $height={bgHeight}
        isRounded={isRounded}
      >
        <StyledTextWrapper>
          <StyledSubtitle>{data.subtitle}</StyledSubtitle>
          <StyledTitle
            dangerouslySetInnerHTML={{ __html: data.title }}
            $color={createColorWithThemeByLocation()}
            gradientType={createColorByLocation()}
          />
        </StyledTextWrapper>
      </StyleBackground>
      {/* {data.images.map((img, i) => (
        <StyledHiddenImage key={img} src={img} alt={`Background ${i}`} />
      ))} */}
    </SectionWrap>
  );
};

IntroBlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }),
  gradientType: PropTypes.oneOf([
    "client",
    "candidate",
    "about-us",
    "services",
    "client-industry",
    "candidate-industry",
    "candidate-jobs",
  ]),
  backgroundColor: PropTypes.string,
  bgHeight: PropTypes.string,
  styles: PropTypes.arrayOf(PropTypes.string),
  isRounded: PropTypes.bool,
};

IntroBlock.defaultProps = {
  backgroundColor: "transparent",
  bgHeight: "100vh",
  isRounded: true,
};

export default IntroBlock;
