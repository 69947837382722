import image1 from "@data/images/box-image/image-03-330x330.jpg";
import image2 from "@data/images/box-image/image-05-330x330.jpg";
import image3 from "@data/images/box-image/image-06-330x330.jpg";

export const data = [
  {
    id: 1,
    image: { src: image1 },
    title: "Reliable Multi-function Technology",
    description:
      "Our technical experts have a flair for developing <strong>clean-coded websites</strong> based on customers’ needs, global guidelines and trends.",
  },
  {
    id: 2,
    image: { src: image2 },
    title: "Efficient Database Security ",
    description:
      "Our technical experts have a flair for developing <strong>clean-coded websites</strong> based on customers’ needs, global guidelines and trends.",
  },
  {
    id: 3,
    image: { src: image3 },
    title: "Reliable Multi-function Technology",
    description:
      "Our technical experts have a flair for developing <strong>clean-coded websites</strong> based on customers’ needs, global guidelines and trends.",
  },
];
