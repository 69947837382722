import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query PrivacyPolicyHeaderDataQuery {
    allStrapiPrivacyPolicyHeader {
      edges {
        node {
          left_links {
            text
            url
          }
          right_links {
            text
            url
          }
          button {
            text
            url
          }
        }
      }
    }
  }
`;

const usePrivacyPolicyHeaderData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiPrivacyPolicyHeader?.edges[0]?.node;
};

export default usePrivacyPolicyHeaderData;
