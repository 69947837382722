import React from "react";
// ui
import { Container } from "@ui/wrapper";
// hooks
import { useFooterData } from "@hooks";
// utils
import { createContactUsLinkByLocation } from "@utils/content.utils";
// styles
import {
  FooterRoot,
  FooterWrapper,
  LeftText,
  StyledList,
  StyledLink,
} from "./style";

const Footer = () => {
  const footerData = useFooterData();

  return (
    <FooterRoot>
      <Container>
        <FooterWrapper>
          <LeftText>
            {footerData.left_text}
            <br />
            Powered by{" "}
            <a
              href="https://seedium.io"
              rel="noopener noreferrer"
              target="_blank"
            >
              Seedium
            </a>
          </LeftText>
          <StyledList>
            {footerData.links.map((link, i) => (
              <li key={i}>
                <StyledLink
                  to={createContactUsLinkByLocation(link.url)}
                  $isLast={i === footerData.links.length - 1}
                >
                  {link.text}
                </StyledLink>
              </li>
            ))}
          </StyledList>
        </FooterWrapper>
      </Container>
    </FooterRoot>
  );
};

export default Footer;
