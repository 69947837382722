import React from "react";
import PropTypes from "prop-types";
// ui
import Button from "@ui/button";
import Image from "@ui/image";
// utils
import { createColorWithThemeByLocation } from "@utils/content.utils";
// types
import { ImageType } from "@utils/types";
// styles
import {
  BoxLargeImgWrap,
  BoxLargeImgInner,
  BoxLargeImgMedia,
  BoxLargeImgBtnWrap,
  BoxLargeImgBtnInner,
  BoxLargeImgHeading,
  HeadingWrap,
  TextWrap,
  BoxLargeImgLink,
  BoxLargeImgContent,
} from "./style";

const BoxImageItem = ({
  image,
  title,
  desc,
  path,
  className,
  ...restProps
}) => {
  return (
    <BoxLargeImgWrap
      className={className}
      $color={createColorWithThemeByLocation()}
      {...restProps}
    >
      <BoxLargeImgInner>
        {image?.src && (
          <BoxLargeImgMedia>
            <Image src={image.src} alt={image?.alt || title} />
            <BoxLargeImgBtnWrap>
              <BoxLargeImgBtnInner>
                <Button color="light" disableHover={true} px="30px" path={path}>
                  Learn more
                </Button>
              </BoxLargeImgBtnInner>
            </BoxLargeImgBtnWrap>
            <BoxLargeImgHeading>
              {title && <HeadingWrap>{title}</HeadingWrap>}
            </BoxLargeImgHeading>
            <BoxLargeImgLink path={path}>{title}</BoxLargeImgLink>
          </BoxLargeImgMedia>
        )}
        <BoxLargeImgContent>
          {desc && <TextWrap dangerouslySetInnerHTML={{ __html: desc }} />}
        </BoxLargeImgContent>
      </BoxLargeImgInner>
    </BoxLargeImgWrap>
  );
};

BoxImageItem.propTypes = {
  title: PropTypes.string,
  image: PropTypes.shape(ImageType),
  desc: PropTypes.string,
  path: PropTypes.string,
  className: PropTypes.string,
};

BoxImageItem.defaultProps = {
  path: "/",
};

export default BoxImageItem;
