import React from "react";
import PropTypes from "prop-types";
// components
import PostNav from "@components/post-nav/layout-02";
// utils
import { slugify } from "@utils";
// types
import { ImageType } from "@utils/types";
// styles
import { Navigation } from "./style";

const NavigationArea = ({ data }) => {
  return (
    <Navigation>
      {data?.previous && (
        <PostNav
          rel="prev"
          id="prev"
          slug={`blog/${slugify(data?.previous.title)}`}
          title={data?.previous.title}
          image={data?.previous.image}
        />
      )}
      {data?.next && (
        <PostNav
          rel="next"
          slug={`blog/${slugify(data?.next.title)}`}
          title={data?.next.title}
          image={data?.next.image}
        />
      )}
    </Navigation>
  );
};

NavigationArea.propTypes = {
  data: PropTypes.shape({
    previous: PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
      image: PropTypes.shape(ImageType),
    }),
    next: PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
      image: PropTypes.shape(ImageType),
    }),
  }),
};

export default NavigationArea;
