import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query ClientIntroDataQuery {
    allStrapiClientIntro {
      edges {
        node {
          title
          subtitle
          image {
            url
          }
        }
      }
    }
  }
`;

const useClientIntroData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiClientIntro?.edges[0]?.node;
};

export default useClientIntroData;
