import styled from "styled-components";
import { space } from "styled-system";

export const DropdownWrap = styled.div`
  ${space};
`;

export const DropdownMenu = styled.ul`
  position: relative;
`;
