import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query CandidateIndustriesDataQuery {
    allStrapiCandidateIndustry {
      edges {
        node {
          id
          title
          image {
            url
          }
          description
          icon_name
          intro_title
          intro_subtitle
          intro_content
        }
      }
    }
  }
`;

const useCandidateIndustriesData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiCandidateIndustry?.edges;
};

export default useCandidateIndustriesData;
