import React from "react";
import styled, { space, themeGet, laptop, tablet, phone } from "@styled";

export const StyledMedia = styled(({ mb, mt, ...rest }) => <div {...rest} />)`
  ${space}
`;

export const StyledThumb = styled.figure`
  width: 100%;
  height: 570px;

  img {
    border-radius: ${themeGet("radii.md")};
    min-height: 570px;
    inset: 0px;
    height: 100%;
    max-width: none;
    width: 100%;
    object-fit: cover;

    @media (${laptop}) {
      min-height: 453px;
    }

    @media (${tablet}) {
      min-height: 336px;
    }

    @media (${phone}) {
      min-height: 196px;
    }
  }

  @media (${laptop}) {
    height: 453px;
  }

  @media (${tablet}) {
    height: 336px;
  }

  @media (${phone}) {
    height: 196px;
  }
`;
