import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query ClientHeaderDataQuery {
    allStrapiClientHeader {
      edges {
        node {
          left_links {
            text
            url
          }
          right_links {
            text
            url
          }
          button {
            text
            url
          }
        }
      }
    }
  }
`;

const useClientHeaderData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiClientHeader?.edges[0]?.node;
};

export default useClientHeaderData;
