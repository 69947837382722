import styled, { device } from "@styled";

export const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 30px;
  margin-block-start: 36px;
  ${device.medium} {
    flex-direction: row;
  }

  #prev {
    height: 120px;

    img {
      height: 120px;
    }
  }

  div[rel="next"] {
    height: 120px;

    img {
      height: 120px;
    }
  }
`;
