import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query CandidateIndustryHeaderDataQuery {
    allStrapiCandidateIndustryHeader {
      edges {
        node {
          left_links {
            text
            url
          }
          right_links {
            text
            url
          }
        }
      }
    }
  }
`;

const useCandidateIndustryHeaderData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiCandidateIndustryHeader?.edges[0]?.node;
};

export default useCandidateIndustryHeaderData;
