import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query LogosDataQuery {
    allStrapiLogo {
      edges {
        node {
          main_logo {
            url
          }
          client_logo {
            url
          }
          candidate_logo {
            url
          }
        }
      }
    }
  }
`;

const useLogosData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiLogo?.edges[0]?.node;
};

export default useLogosData;
