import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
// ui
import Feedback from "@ui/form-elements/feedback";
// styles
import { customStyles } from "./style";

const SelectField = memo(
  forwardRef(
    ({ feedbackText, showState, state, showErrorOnly, ...props }, ref) => {
      return (
        <>
          <Select
            isClearable={false}
            styles={customStyles(showState)}
            hideSelectedOptions={false}
            ref={ref}
            {...props}
          />
          {feedbackText && showState && (
            <Feedback
              state={state}
              showState={showState}
              showErrorOnly={showErrorOnly}
            >
              {feedbackText}
            </Feedback>
          )}
        </>
      );
    }
  )
);

SelectField.propTypes = {
  feedbackText: PropTypes.string,
  showState: PropTypes.bool,
  showErrorOnly: PropTypes.bool,
  state: PropTypes.oneOf(["success", "warning", "error"]),
};

SelectField.defaultProps = {
  showErrorOnly: true,
};

SelectField.displayName = "SelectField";

export default SelectField;
