import React from "react";
import PropTypes from "prop-types";
// ui
import Button from "@ui/button";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import NumberedList from "@ui/numbered-list";
import Accordion from "@ui/accordion";
// styles
import { SectionWrap, LeftBox } from "./style";

const IntroToOurServices = ({ data }) => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={5}>
            <LeftBox>
              <SectionTitle
                textAlign="left"
                mb="20px"
                title={data.title}
                subtitle={data.subtitle}
                description={data.description}
              />
              <Button path={data.button_link}>{data.button_text}</Button>
            </LeftBox>
          </Col>
          <Col lg={7} xl={5} ml="auto">
            <NumberedList>
              <Accordion
                layout={3}
                data={data.services}
                isNumbered
                disableDefaultActive
              />
            </NumberedList>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

IntroToOurServices.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    button_text: PropTypes.string.isRequired,
    button_link: PropTypes.string.isRequired,
    services: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })
    ),
  }),
};

export default IntroToOurServices;
