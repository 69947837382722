// ui
import { Container } from "@ui/wrapper";
// styles
import styled, { css, phone } from "@styled";

export const SectionWrap = styled.section`
  position: relative;
  min-height: ${(props) => props.$height};
  overflow: hidden;
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const StyleBackground = styled.div`
  ${(props) => {
    switch (props.gradientType) {
      case "client":
        return css`
          background: linear-gradient(
              180deg,
              #007ded 0%,
              rgba(0, 125, 237, 0.29) 21.33%,
              rgba(0, 125, 237, 0.13) 26.82%,
              rgba(249, 243, 241, 0) 37.54%
            ),
            url(${props.url});
        `;
      case "candidate":
        return css`
          background: linear-gradient(
              180deg,
              #1fb591 0%,
              rgba(31, 181, 145, 0.29) 21.33%,
              rgba(31, 181, 145, 0.13) 26.82%,
              rgba(249, 243, 241, 0) 37.54%
            ),
            url(${props.url});
        `;
      default:
        return "";
    }
  }}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  width: 100%;
  max-width: 100vw;
  height: ${(props) => props.$height};
  border-bottom-left-radius: 180px;
  ${({ $styles }) => $styles}

  @media (${phone}) {
    border-bottom-left-radius: 50px;
    background-position: initial;
  }

  ${({ isRounded }) => {
    if (!isRounded)
      return css`
        border-bottom-left-radius: 0 !important;
      `;
  }}
`;

export const StyledTextWrapper = styled(Container)`
  display: grid;
  align-content: end;
  margin-bottom: 105px;

  @media (${phone}) {
    margin-bottom: 60px;
  }
`;

export const StyledSubtitle = styled.h5`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;

  color: #ffffff;
`;

export const StyledTitle = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 70px;
  color: ${({ $color }) => $color};
  margin-top: 12px;

  > span {
    color: #fff;
    ${(props) => {
      switch (props.gradientType) {
        case "primary":
          return css`
            background: linear-gradient(
              89.93deg,
              #007deb 0%,
              rgba(0, 125, 235, 0) 86.13%
            );
          `;
        case "candidate":
          return css`
            background: linear-gradient(
              89.93deg,
              #1fb591 0%,
              rgba(31, 181, 145, 0) 86.13%
            );
          `;
        default:
          return "";
      }
    }}
    border-radius: 10px;
    padding: 10px;
  }

  @media (${phone}) {
    font-size: 35px;

    > span {
      padding: 5px;
    }
  }
`;

export const StyledHiddenImage = styled.img`
  display: none;
`;
