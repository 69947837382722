import React from "react";
import PropTypes from "prop-types";
// components
import Post from "@components/post";
// utils
import { slugify } from "@utils";
import { createStrapiContentUrl } from "@utils/content.utils";
// types
import { PostType } from "@utils/types";
// styles
import { BlogBox } from "./style";

const Posts = ({ data }) => {
  return (
    <div className="blog Area">
      {data.posts.map((post) => {
        const slug = slugify(post.node.title);

        const author = {
          name: post?.node?.author?.name,
          avatar: {
            url: createStrapiContentUrl(post?.node?.author?.avatar?.url),
          },
        };

        const tags = post?.node?.tags.map(({ tag }) => tag);

        return (
          <BlogBox key={slug}>
            <Post
              title={post.node.title}
              slug={slug}
              description={post.node.description}
              postedAt={post.node.date}
              author={author}
              tags={tags}
              image={createStrapiContentUrl(post.node.image.url)}
            />
          </BlogBox>
        );
      })}
    </div>
  );
};

Posts.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.arrayOf(PostType),
  }),
};

export default Posts;
