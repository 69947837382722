import React, { useMemo } from "react";
import PropTypes from "prop-types";
// components
import Seo from "@components/seo";
import IntroBlock from "@components/intro-block";
// containers
import Header from "@containers/header";
import Industries from "@containers/industries";
import SearchBar from "@containers/search-bar";
import Partners from "@containers/partners";
import CTABlock from "@containers/cta-block";
import BlockWrapper from "@containers/block-wrapper";
import Resources from "@containers/resources";
// templates
import CandidateJobsTemplate from "src/templates/candidate-jobs";
// layouts
import MainLayout from "@layout/main-layout";
// hooks
import {
  useCandidateHeaderData,
  useCandidateIntroData,
  useClientLogosData,
  useCandidateSearchBarData,
  useCandidateIndustriesBlockData,
  useResourcesData,
  usePostsPreviewData,
  useCandidateCtaData,
  useSearchURLParams,
} from "@hooks";
// utils
import { createStrapiContentUrl } from "@utils/content.utils";

const CandidatePage = ({ location }) => {
  // hooks
  const candidateHeaderData = useCandidateHeaderData();
  const candidateIntroData = useCandidateIntroData();
  const clientLogosData = useClientLogosData();
  const candidateSearchBarData = useCandidateSearchBarData();
  const candidateIndustriesBlockData = useCandidateIndustriesBlockData();
  const candidateCtaData = useCandidateCtaData();
  const resourcesData = useResourcesData();
  const postsPreviewData = usePostsPreviewData();
  const searchJobsValue = useSearchURLParams("rSearch");
  const categoryJobsValue = useSearchURLParams("category");
  const locationJobsValue = useSearchURLParams("location");
  // memo
  const introBlockData = useMemo(
    () => ({
      ...candidateIntroData,
      image: createStrapiContentUrl(candidateIntroData.image.url),
    }),
    [candidateIntroData]
  );

  const resourcesFormattedData = useMemo(
    () => ({
      ...resourcesData,
      posts: postsPreviewData.map((post) => post.node),
    }),
    [resourcesData, postsPreviewData]
  );

  if (!!searchJobsValue || !!categoryJobsValue || !!locationJobsValue)
    return <CandidateJobsTemplate location={location} />;

  return (
    <MainLayout location={location}>
      <Seo
        title="Jobs in Australia - Recruitment Agency - Stream Talent"
        description="Find your next career move with Stream Talent. We will help find you the right opportunity for your next career move. Talk to a recruiter today."
      />
      <Header data={candidateHeaderData} />
      <main className="site-wrapper-reveal">
        <IntroBlock data={introBlockData} gradientType="candidate" />
        <Partners data={clientLogosData?.logos} />
        <SearchBar data={candidateSearchBarData} />
        <Industries data={candidateIndustriesBlockData} bg="gray.500" />
        {/* TODO: uncomment it in v2 */}
        {/* <Testimonials data={candidateQuotesData} />*/}
        <CTABlock data={candidateCtaData} />
        <BlockWrapper>
          <Resources data={resourcesFormattedData} />
        </BlockWrapper>
      </main>
    </MainLayout>
  );
};

CandidatePage.propTypes = {
  location: PropTypes.shape({}),
};

export default CandidatePage;
