const ctaData = {
  headings: [
    {
      level: "h3",
      content:
        "Assess your business potentials and find opportunities <span>for bigger success </span>",
    },
  ],
  buttons: [
    {
      id: 1,
      path: "/contact-us",
      color: "light",
      icon: "far fa-comment-alt-dots",
      iconposition: "left",
      icondistance: "8px",
      iconsize: "16px",
      hoverStyle: "three",
      content: "Let's talk",
    },
    {
      id: 2,
      path: "/contact-us",
      color: "secondary",
      icon: "far fa-info-circle",
      iconposition: "left",
      icondistance: "8px",
      iconsize: "16px",
      content: "Get Info",
    },
  ],
};

export default ctaData;
