// components
import MottoText from "@components/motto";
// ui
import Image from "@ui/image";
// utils
import { createColorWithThemeByLocation } from "@utils/content.utils";
// styles
import styled, { tablet, device } from "@styled";

export const StyledSection = styled.section`
  padding-block: 40px;
  ${device.medium} {
    padding-block: 60px;
  }
  ${device.large} {
    padding-block: 80px;
  }
`;

export const StyledTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 45px;
`;

export const StyledDescription = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  margin-top: 20px;

  b {
    font-weight: bold;
  }
`;

export const ListWrapper = styled.div`
  margin-top: 30px;
`;

export const StyledList = styled.ul`
  margin-top: 10px;
`;

export const StyledItem = styled.li`
  display: flex;
  align-items: center;

  > span {
    color: ${createColorWithThemeByLocation()};
  }
`;

export const StyledItemText = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  margin-left: 7px;
`;

export const StyledLink = styled(MottoText)`
  text-align: left;
  margin-top: 40px;
`;

export const StyledImage = styled(Image)`
  border-bottom-left-radius: 68px;
  border-top-right-radius: 68px;

  @media (${tablet}) {
    margin-top: 60px;
    border-bottom-left-radius: 42px;
    border-top-right-radius: 42px;
  }
`;
