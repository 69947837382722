import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query CandidateIndustryIntroDataQuery {
    allStrapiCandidateIndustryIntro {
      edges {
        node {
          title
          subtitle
        }
      }
    }
  }
`;

const useCandidateIndustryIntroData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiCandidateIndustryIntro?.edges[0]?.node;
};

export default useCandidateIndustryIntroData;
