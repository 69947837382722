import React from "react";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
// types
import { color } from "@utils/types";
// style
import { StyledAccordion, NumberedWrapper, Marker } from "./style";

const AccordionWrap = ({
  defaultActiveKey,
  data,
  layout,
  disableDefaultActive,
  isNumbered,
  color,
  collapsedColor,
  ...props
}) => {
  return (
    <StyledAccordion
      layout={layout}
      $color={color}
      $collapsedColor={collapsedColor}
      {...props}
    >
      <Accordion
        defaultActiveKey={
          disableDefaultActive || defaultActiveKey || data[0].id
        }
      >
        {isNumbered
          ? data?.map((item, i) => {
              let number = i + 1;
              let formattedNumber = number < 10 ? `0${number}` : number;

              return (
                <NumberedWrapper key={item.id}>
                  {number && <Marker>{formattedNumber}</Marker>}
                  <Accordion.Item eventKey={item.id}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body>{item.description}</Accordion.Body>
                  </Accordion.Item>
                </NumberedWrapper>
              );
            })
          : data?.map((item) => (
              <Accordion.Item key={item.id} eventKey={item.id}>
                <Accordion.Header>{item.title}</Accordion.Header>
                <Accordion.Body>{item.description}</Accordion.Body>
              </Accordion.Item>
            ))}
      </Accordion>
    </StyledAccordion>
  );
};

AccordionWrap.propTypes = {
  layout: PropTypes.oneOf([1, 2, 3]),
  defaultActiveKey: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  disableDefaultActive: PropTypes.bool,
  isNumbered: PropTypes.bool,
  color: color,
  collapsedColor: color,
};

AccordionWrap.defaultProps = {
  layout: 1,
  isNumbered: false,
  color: "heading",
  collapsedColor: "heading",
};

export default AccordionWrap;
