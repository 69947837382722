import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query CandidateIndustryJobsDataQuery {
    allStrapiCandidateIndustryJob {
      edges {
        node {
          title
          subtitle
        }
      }
    }
  }
`;

const useCandidateIndustryJobsData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiCandidateIndustryJob?.edges[0].node;
};

export default useCandidateIndustryJobsData;
