// utils
import { isBrowser } from "@utils";

const useSearchURLParams = (param) => {
  if (!isBrowser) return null;

  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get(param);
};

export default useSearchURLParams;
