import React, { useMemo } from "react";
import PropTypes from "prop-types";
// components
import Seo from "@components/seo";
import PageHeader from "@components/page-header";
// containers
import Header from "@containers/header";
import ContactUsWrapper from "@containers/contact-us-wrapper";
import ContactUsInfo from "@containers/contact-us-info";
// layouts
import MainLayout from "@layout/main-layout";
// hooks
import {
  useCandidateContactUsHeaderData,
  useCandidateContactUsInfoData,
} from "@hooks";

const CandidateContactUsPage = ({ location }) => {
  // hooks
  const candidateContactUsHeaderData = useCandidateContactUsHeaderData();
  const candidateContactUsInfoData = useCandidateContactUsInfoData();
  // memo
  const pageHeaderData = useMemo(
    () => ({
      title: candidateContactUsInfoData.page_title,
      subtitle: candidateContactUsInfoData.page_subtitle,
    }),
    [candidateContactUsInfoData]
  );

  const contactUsWrapperData = useMemo(
    () => ({
      title: candidateContactUsInfoData.title,
      description: candidateContactUsInfoData.description,
    }),
    [candidateContactUsInfoData]
  );

  const contactUsInfoData = useMemo(() => {
    const { title, description, ...rest } = candidateContactUsInfoData;
    return rest;
  }, [candidateContactUsInfoData]);

  return (
    <MainLayout location={location}>
      <Seo
        title="Contact - Stream Talent - Find New Jobs in Australia"
        description="Talk to a recruitment consultant about finding a new job  Our industry specific recruiters will give you advice on how to find a new job and your job search. "
      />
      <Header data={candidateContactUsHeaderData} />
      <main className="site-wrapper-reveal">
        <PageHeader data={pageHeaderData} bgColor="candidate" />
        <ContactUsWrapper data={contactUsWrapperData} />
        <ContactUsInfo data={contactUsInfoData} />
      </main>
    </MainLayout>
  );
};

CandidateContactUsPage.propTypes = {
  location: PropTypes.shape({}),
};

export default CandidateContactUsPage;
