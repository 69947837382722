import React, { useMemo } from "react";
import PropTypes from "prop-types";
// components
import Seo from "@components/seo";
import IntroBlock from "@components/intro-block";
import VideoIntro from "@components/video-intro";
import IndustryTextIntro from "@components/industry-text-intro";
import IndustryDescription from "@components/industry-description";
// containers
import Header from "@containers/header";
import TeamMembers from "@containers/team-members";
import TeamMembersWidget from "@containers/team-members-widget";
import CTABlock from "@containers/cta-block";
import BlockWrapper from "@containers/block-wrapper";
import IndustryJobs from "@containers/industry-jobs";
import Resources from "@containers/resources";
// templates
import CandidateJobsTemplate from "src/templates/candidate-jobs";
// layouts
import MainLayout from "@layout/main-layout";
// hooks
import {
  useCandidateIndustryHeaderData,
  useMainVideoPodData,
  useCandidateIndustryJobsData,
  useIndustryDescriptionData,
  useCandidateCtaData,
  useCandidateIndustryIntroData,
  useTeamMembersData,
  useSearchURLParams,
  useResourcesData,
  usePostsPreviewData,
} from "@hooks";
// utils
import { isBrowser } from "@utils";
import { createStrapiContentUrl } from "@utils/content.utils";
// types
import { IndustryType } from "@utils/types";

const CandidateIndustryTemplate = ({ pageContext, location }) => {
  // hooks
  const candidateIndustryHeaderData = useCandidateIndustryHeaderData();
  const industryDescriptionData = useIndustryDescriptionData();
  const mainVideoPodData = useMainVideoPodData();
  const candidateIndustryJobsData = useCandidateIndustryJobsData();
  const candidateCtaData = useCandidateCtaData();
  const candidateIndustryIntroData = useCandidateIndustryIntroData();
  const teamMembersData = useTeamMembersData();
  const resourcesData = useResourcesData();
  const postsPreviewData = usePostsPreviewData();
  const searchJobsValue = useSearchURLParams("rSearch");
  const teamMemberValue = useSearchURLParams("consultant");
  const categoryJobsValue = useSearchURLParams("category");
  const locationJobsValue = useSearchURLParams("location");
  // memo
  const industryIntroData = useMemo(
    () => ({
      title: pageContext.industry.intro_title,
      subtitle: pageContext.industry.intro_subtitle,
      content: pageContext.industry.intro_content,
    }),
    [pageContext.industry]
  );

  const pageIntroData = useMemo(
    () => ({
      title: pageContext.industry.page_title,
      subtitle: candidateIndustryIntroData.subtitle,
      image: createStrapiContentUrl(pageContext.industry.image.url),
    }),
    [candidateIndustryIntroData, pageContext.industry]
  );

  const industryDescriptionFormattedData = useMemo(() => {
    const linkUrl = industryDescriptionData.link_url;

    return {
      ...industryDescriptionData,
      link_url: isBrowser ? `${window.location}${linkUrl}` : linkUrl,
      description: pageContext.industry.clients_we_service_text,
      roles: pageContext.industry.roles_we_recruit,
    };
  }, [
    industryDescriptionData,
    pageContext.industry.clients_we_service_text,
    pageContext.industry.roles_we_recruit,
  ]);

  const teamMembersFormattedData = useMemo(
    () => ({ ...teamMembersData, tag: pageContext.industry.team_members_tag }),
    [pageContext.industry.team_members_tag, teamMembersData]
  );

  const industryJobsData = useMemo(
    () => ({
      ...candidateIndustryJobsData,
      tag: pageContext.industry.industry_jobs_tag,
    }),
    [pageContext.industry.industry_jobs_tag, candidateIndustryJobsData]
  );

  const resourcesFormattedData = useMemo(
    () => ({
      ...resourcesData,
      posts: postsPreviewData.map((post) => post.node),
    }),
    [resourcesData, postsPreviewData]
  );

  if (!isBrowser) return null;

  if (!!searchJobsValue || !!categoryJobsValue || !!locationJobsValue)
    return <CandidateJobsTemplate location={location} />;

  if (!!teamMemberValue) {
    return (
      <MainLayout location={location}>
        <Seo title="Team member" />
        <main className="site-wrapper-reveal">
          <TeamMembersWidget tag={pageContext.industry.team_members_tag} />
          <BlockWrapper onlyTop>
            <CTABlock data={candidateCtaData} />
          </BlockWrapper>
        </main>
      </MainLayout>
    );
  }

  return (
    <MainLayout location={location}>
      <Seo
        title={pageContext.industry.meta_title}
        description={pageContext.industry.meta_description}
      />
      <Header data={candidateIndustryHeaderData} />
      <main className="site-wrapper-reveal">
        <IntroBlock data={pageIntroData} gradientType="candidate" />
        <IndustryTextIntro data={industryIntroData} />
        <IndustryDescription data={industryDescriptionFormattedData} />
        <TeamMembers data={teamMembersFormattedData} />
        <VideoIntro data={mainVideoPodData} buttonColor="#333333" />
        {/* TODO: uncomment it in v2 */}
        {/* <Testimonials data={clientQuotesData} />*/}
        <BlockWrapper>
          <IndustryJobs data={industryJobsData} />
        </BlockWrapper>
        <BlockWrapper onlyTop>
          <CTABlock data={candidateCtaData} />
        </BlockWrapper>
        <BlockWrapper>
          <Resources data={resourcesFormattedData} />
        </BlockWrapper>
      </main>
    </MainLayout>
  );
};

CandidateIndustryTemplate.propTypes = {
  pageContext: PropTypes.shape({
    industry: IndustryType.isRequired,
  }),
  location: PropTypes.shape({}),
};

export default CandidateIndustryTemplate;
