import React from "react";
import PropTypes from "prop-types";
// ui
import Button from "@ui/button";
// utils
import { createColorByLocation } from "@utils/content.utils";
// styles
import {
  BoxIconWrap,
  BoxIconInner,
  BoxIconImg,
  BoxIconContent,
  BoxIcontitle,
  BoxIconText,
  BoxIconBtn,
} from "./style";

const Industry = ({ id, icon, title, desc, path, className, ...restProps }) => {
  return (
    <BoxIconWrap>
      <BoxIconInner className={className} {...restProps}>
        {icon && (
          <BoxIconImg>
            {/* TODO: find a way to use animated svg here */}
            {/* <ReactVivus
              id={`svg-${id}`}
              option={{
                file: icon,
                type: "oneByOne",
                animTimingFunction: "EASE",
                duration: 250,
              }}
            /> */}
            <i className={icon}></i>
          </BoxIconImg>
        )}
        <BoxIconContent>
          {title && <BoxIcontitle>{title}</BoxIcontitle>}
          {desc && <BoxIconText>{desc}</BoxIconText>}
          {path && (
            <BoxIconBtn>
              <Button
                path={path}
                variant="texted"
                fontWeight={400}
                disableHover
                icondistance="8px"
                icon="far fa-long-arrow-right"
                color={createColorByLocation()}
              >
                Discover now
              </Button>
            </BoxIconBtn>
          )}
        </BoxIconContent>
      </BoxIconInner>
    </BoxIconWrap>
  );
};

Industry.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  path: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
};

Industry.defaultProps = {
  path: "/",
};

export default Industry;
