import React from "react";
import PropTypes from "prop-types";
// ui
import { Container } from "@ui/wrapper";
// components
import SectionTitle from "@components/section-title";
// containers
import BlockWrapper from "@containers/block-wrapper";
import SearchBarWidget from "@containers/search-bar-widget";

const SearchBar = ({ data }) => {
  return (
    <BlockWrapper>
      <Container>
        <SectionTitle
          mb={["30px", null, "40px"]}
          subtitle={data.subtitle}
          title={data.title}
        />
      </Container>
      <SearchBarWidget />
    </BlockWrapper>
  );
};

SearchBar.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }).isRequired,
};

export default SearchBar;
