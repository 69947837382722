import React from "react";
import PropTypes from "prop-types";
// components
import SectionTitle from "@components/section-title";
// ui
import { Container } from "@ui/wrapper";
// styles
import { SectionWrap, StyledContent } from "./style";

const TextIntro = ({ data }) => {
  return (
    <Container>
      <SectionWrap>
        <SectionTitle
          mb={["30px", null, "40px"]}
          subtitle={data?.subtitle}
          title={data?.title}
        />
        <StyledContent
          dangerouslySetInnerHTML={{
            __html: data?.content.data.childMarkdownRemark.html,
          }}
        />
      </SectionWrap>
    </Container>
  );
};

TextIntro.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    content: PropTypes.PropTypes.shape({
      data: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
          html: PropTypes.string,
        }),
      }),
    }),
  }),
};

export default TextIntro;
