import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query FooterQuery {
    allStrapiFooter {
      edges {
        node {
          left_text
          links {
            text
            url
          }
        }
      }
    }
  }
`;

const useFooterData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiFooter?.edges[0]?.node;
};

export default useFooterData;
