import React, { useMemo } from "react";
import PropTypes from "prop-types";
// components
import Seo from "@components/seo";
import PageHeader from "@components/page-header";
// containers
import Header from "@containers/header";
import ContactUsWrapper from "@containers/contact-us-wrapper";
import ContactUsInfo from "@containers/contact-us-info";
// layouts
import MainLayout from "@layout/main-layout";
// hooks
import {
  useClientContactUsHeaderData,
  useClientContactUsInfoData,
} from "@hooks";

const ClientContactUsPage = ({ location }) => {
  // hooks
  const clientContactUsHeaderData = useClientContactUsHeaderData();
  const clientContactUsInfoData = useClientContactUsInfoData();
  // memo
  const pageHeaderData = useMemo(
    () => ({
      title: clientContactUsInfoData.page_title,
      subtitle: clientContactUsInfoData.page_subtitle,
    }),
    [clientContactUsInfoData]
  );

  const contactUsWrapperData = useMemo(
    () => ({
      title: clientContactUsInfoData.title,
      description: clientContactUsInfoData.description,
    }),
    [clientContactUsInfoData]
  );

  const contactUsInfoData = useMemo(() => {
    const { title, description, ...rest } = clientContactUsInfoData;
    return rest;
  }, [clientContactUsInfoData]);

  return (
    <MainLayout location={location}>
      <Seo
        title="Contact - Stream Talent - Find Recruitment Consultants "
        description="Talk to a recruitment consultant about finding new employees. Our industry specific recruiters will discuss your needs to find new staff quickly. "
      />
      <Header data={clientContactUsHeaderData} />
      <main className="site-wrapper-reveal">
        <PageHeader data={pageHeaderData} />
        <ContactUsWrapper data={contactUsWrapperData} />
        <ContactUsInfo data={contactUsInfoData} />
      </main>
    </MainLayout>
  );
};

ClientContactUsPage.propTypes = {
  location: PropTypes.shape({}),
};

export default ClientContactUsPage;
