import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query HomeIntroDataQuery {
    allStrapiHomeIntro {
      edges {
        node {
          left_title
          left_subtitle
          left_image {
            url
          }
          left_link
          right_title
          right_subtitle
          right_image {
            url
          }
          right_link
        }
      }
    }
  }
`;

const useHomeIntroData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiHomeIntro?.edges[0]?.node;
};

export default useHomeIntroData;
