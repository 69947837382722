import image1 from "@data/images/icons/icon-21-68x68.jpg";
import image2 from "@data/images/icons/icon-22-68x68.png";
import image3 from "@data/images/icons/icon-23-68x68.png";
import image4 from "@data/images/icons/icon-24-68x68.png";
import image5 from "@data/images/icons/icon-25-68x68.png";
import image6 from "@data/images/icons/icon-26-68x68.png";

export const data = [
  {
    id: 1,
    image: { src: image1 },
    title: "Expert Typography",
    description:
      "Your site’s typography will benefit from beautiful fonts provided by Font Awesome 5 Pro plugin.",
  },
  {
    id: 2,
    image: { src: image2 },
    title: "Fast Loading Speed",
    description:
      "Mitech gets a very high pagespeed scores on Pagespeed Insight, Pingdom Tools & GTmetrix.",
  },
  {
    id: 3,
    image: { src: image3 },
    title: "SEO Optimization",
    description:
      "All elements are fully optimized to help your site compete with other similar ones in search engine.",
  },
  {
    id: 4,
    image: { src: image4 },
    title: "Cross-browser Adaptability",
    description:
      "We run thorough tests on different browsers to ensure optimal performance on all devices.",
  },
  {
    id: 5,
    image: { src: image5 },
    title: "Prompt Support",
    description:
      "Any issues with theme configuration and site building will be solved promptly by our professionals.",
  },
  {
    id: 6,
    image: { src: image6 },
    title: "Abundant Shortcodes",
    description:
      "A wide ranging of shortcodes are added to Mitech for assisting users on page building process.",
  },
];
