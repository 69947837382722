import React, { useMemo } from "react";
import PropTypes from "prop-types";
// components
import Seo from "@components/seo";
// containers
import LegalPageContainer from "@containers/legal-page-container";
import Header from "@containers/header";
// layouts
import MainLayout from "@layout/main-layout";
// hooks
import { usePrivacyPolicyData, usePrivacyPolicyHeaderData } from "@hooks";

const PrivacyPolicyPage = ({ location }) => {
  // hooks
  const privacyPolicyData = usePrivacyPolicyData();
  const privacyPolicyHeaderData = usePrivacyPolicyHeaderData();
  // memo
  const privacyPolicyFormattedData = useMemo(
    () => ({
      title: "Privacy Policy",
      content: privacyPolicyData,
    }),
    [privacyPolicyData]
  );

  return (
    <MainLayout location={location}>
      <Seo title="Privacy Policy" />
      <Header
        data={privacyPolicyHeaderData}
        isDefaultSticky
        hoverColor="#3DC7FA"
      />
      <main className="site-wrapper-reveal">
        <LegalPageContainer data={privacyPolicyFormattedData} />
      </main>
    </MainLayout>
  );
};

PrivacyPolicyPage.propTypes = {
  location: PropTypes.shape({}),
};

export default PrivacyPolicyPage;
