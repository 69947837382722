import { Link } from "gatsby";
import styled, { css, themeGet, phone } from "@styled";

export const FooterRoot = styled.footer`
  background-color: ${themeGet("colors.gray.100")};
  padding: 40px 0;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (${phone}) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const LeftText = styled.h5`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${themeGet("colors.text")};
`;

export const StyledList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (${phone}) {
    margin-top: 10px;
  }
`;

export const StyledLink = styled(Link)`
  color: ${themeGet("colors.text")} !important;
  position: relative;
  padding-left: 9px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -4px;
    width: 1.5px;
    height: 50%;
    transform: translateY(-50%);
    background-color: ${themeGet("colors.silver")};
  }

  &::after {
    content: "";
    width: 0;
    height: 1px;
    display: block;
    background-color: ${themeGet("colors.primary")};
    transition: 0.3s;
  }

  &:hover {
    color: ${themeGet("colors.primary")} !important;

    &::after {
      width: 100%;
    }
  }

  @media (${phone}) {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  ${({ $isLast }) => css`
    padding-right: ${$isLast ? "0" : "4px"};

    ${$isLast
      ? `&::before {
      height: 0;
    }`
      : ""}
  `}
`;
