import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";
// styles
import styled, { css, themeGet } from "@styled";

export const BurgerRoot = styled.div`
  position: fixed;
  z-index: 1000;
`;

export const Menu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  background: ${({ gradientType }) =>
      gradientType === "client" &&
      "linear-gradient(180deg, rgba(0, 125, 235, 0.5) 0%,rgba(61, 199, 250, 0.5) 100%), linear-gradient(180deg, #007DEB 0%, rgba(61, 199, 250, 0.84) 100%)"}
    ${({ gradientType }) =>
      gradientType === "candidate" &&
      "linear-gradient(180deg, rgba(31, 181, 145, 0.5) 0%, rgba(64, 212, 168, 0.5) 100%), linear-gradient(180deg, #1FB591 0%, rgba(64, 212, 168, 0.84) 100%)"};
  backdrop-filter: blur(95px);
  z-index: 100;
  min-width: unset;
  transform: scaleX(0);
  transform-origin: right;
  transition: 0.3s;
  transition-timing-function: ease;
  overflow: hidden;
  padding: 80px 0;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transition-delay: 0.3s;
      transform: scaleX(1);
    `}
`;

export const MenuWrapper = styled.div`
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  overflow-y: auto;
`;

export const Links = styled.ul`
  margin-top: 24px;
`;

export const StyledLink = styled(AnchorLink)`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: initial !important;
  margin-top: 32px;

  &:hover {
    color: ${themeGet("colors.heading")};
    background: linear-gradient(
      89.93deg,
      #ffffff 49.18%,
      rgba(196, 196, 196, 0) 84.92%
    );
    border-radius: 10px;
    width: fit-content;
  }
`;

export const StyledButton = styled(Link)`
  text-decoration: initial !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff !important;
  cursor: pointer;
  transition: all 0.3s ease;
  background: ${({ $color }) => $color};
  border-radius: 4px;
  padding: 10px 26px;
  margin-top: 50px;
`;
