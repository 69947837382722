import { themeGet } from "@styled";
// utils
import { isBrowser } from "@utils";

const createStrapiContentUrl = (url) => {
  return `${
    process.env.NODE_ENV === "development" ? process.env.STRAPI_URL : ""
  }${url}`;
};

const createElementTypeByLocation = (
  color1,
  color2,
  searchString = "candidate"
) => {
  return isBrowser && window.location.href.includes(searchString)
    ? color1
    : color2;
};

const createColorWithThemeByLocation = (searchString = "candidate") => {
  return createElementTypeByLocation(
    themeGet("colors.candidate"),
    themeGet("colors.primary"),
    searchString
  );
};

const createColorByLocation = () => {
  return createElementTypeByLocation("candidate", "primary");
};

const createContactUsLinkByLocation = (url) => {
  return url === "/contact-us"
    ? `/${createElementTypeByLocation("candidate", "client")}${url}`
    : url;
};

module.exports = {
  createStrapiContentUrl,
  createElementTypeByLocation,
  createColorWithThemeByLocation,
  createColorByLocation,
  createContactUsLinkByLocation,
};
