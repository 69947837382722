import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query ClientLogosDataQuery {
    allStrapiLogoClient {
      edges {
        node {
          logos {
            id
            image {
              url
            }
            hover_image {
              url
            }
          }
        }
      }
    }
  }
`;

const useClientLogosData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiLogoClient?.edges[0].node;
};

export default useClientLogosData;
