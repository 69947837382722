import { graphql, useStaticQuery } from "gatsby";

// export const query = graphql`
//   query ServicesDataQuery {
//     allStrapiService {
//       edges {
//         node {
//           title
//           subtitle
//           description
//           services {
//             id
//             title
//             link
//           }
//           button_text
//           button_link
//         }
//       }
//     }
//   }
// `;

// TODO: uncomment it later
const useServicesData = () => {
  // const data = useStaticQuery(query);

  // return data?.allStrapiService?.edges[0]?.node;
  return null;
};

export default useServicesData;
