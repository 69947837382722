import React, { useEffect } from "react";
import PropTypes from "prop-types";
// utils
import {
  createElementTypeByLocation,
  createColorWithThemeByLocation,
} from "@utils/content.utils";
// types
import { LinkType } from "@utils/types";
// styles
import {
  BurgerRoot,
  Menu,
  MenuWrapper,
  Links,
  StyledLink,
  StyledButton,
} from "./style";

const Burger = ({ isOpen, links, button }) => {
  useEffect(() => {
    if (typeof document !== "undefined") {
      if (isOpen) document.body.style.overflow = "hidden";

      return () => {
        document.body.style.overflow = "unset";
      };
    }
  }, [isOpen]);

  return (
    <BurgerRoot isOpen={isOpen}>
      <Menu
        isOpen={isOpen}
        gradientType={createElementTypeByLocation("candidate", "client")}
      >
        <MenuWrapper>
          {Array.isArray(links) && (
            <Links>
              {links.map((link, i) => (
                <li key={`${link.text}${i}`}>
                  <StyledLink to={link.url}>{link.text}</StyledLink>
                </li>
              ))}
            </Links>
          )}
          {/* TODO: add hover effect for the button */}
          {button && (
            <StyledButton
              to={button.url}
              $color={createColorWithThemeByLocation("client")}
            >
              {button.text}
            </StyledButton>
          )}
        </MenuWrapper>
      </Menu>
    </BurgerRoot>
  );
};

Burger.propTypes = {
  isOpen: PropTypes.bool,
  links: PropTypes.arrayOf(LinkType),
  button: LinkType,
};

export default Burger;
