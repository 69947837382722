import React from "react";
import PropTypes from "prop-types";
import Image from "@ui/image";
import { ImageType } from "@utils/types";
import {
  BoxImgWrapper,
  BoxImgInner,
  BoxImgMedia,
  BoxImgContent,
  HeadingWrap,
  BoxImgLink,
} from "./style";

const BoxImage = ({ image, title, path, className, ...restProps }) => {
  return (
    <BoxImgWrapper className={className} {...restProps}>
      <BoxImgInner>
        {image?.src && (
          <BoxImgMedia>
            <Image src={image.src} alt={image?.alt || title} />
          </BoxImgMedia>
        )}
        <BoxImgContent>
          {title && <HeadingWrap>{title}</HeadingWrap>}
        </BoxImgContent>
        <BoxImgLink to={path}>{title}</BoxImgLink>
      </BoxImgInner>
    </BoxImgWrapper>
  );
};

BoxImage.propTypes = {
  image: PropTypes.shape(ImageType),
  title: PropTypes.string,
  path: PropTypes.string,
  className: PropTypes.string,
};

BoxImage.defaultProps = {
  path: "/",
};

export default BoxImage;
