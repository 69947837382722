import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query BlogPostsPreviewDataQuery {
    allStrapiPost(limit: 3, sort: { order: DESC, fields: date }) {
      edges {
        node {
          id
          title
          description
          image {
            url
          }
        }
      }
    }
  }
`;

const usePostsPreviewData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiPost.edges;
};

export default usePostsPreviewData;
