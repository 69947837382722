import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
// styles
import { PageHeaderWrap, StyledBG } from "./style";

const PageHeaderWithImage = ({ pageContext, location, title, tag }) => {
  const labelArr = useMemo(() => {
    const customCrumbLabel = location.pathname.toLowerCase();
    const crumbLabelArr = customCrumbLabel.split("/");
    const label = crumbLabelArr[crumbLabelArr.length - 1];
    return label.split("-");
  }, [location.pathname]);

  const disableLinks = useMemo(() => {
    let links = ["/blog/page", "/blog/tags"];

    if (tag) links.push(`/blog/tags/${tag}/page`);

    return links;
  }, [tag]);

  return (
    <PageHeaderWrap>
      <StyledBG />
      <Breadcrumb
        title={title}
        crumbs={
          pageContext?.breadcrumb?.crumbs.filter(
            (item) => item.pathname !== "/"
          ) || []
        }
        crumbLabel={labelArr.join(" ")}
        disableLinks={disableLinks}
      />
    </PageHeaderWrap>
  );
};

PageHeaderWithImage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  pageContext: PropTypes.shape({
    breadcrumb: PropTypes.shape({
      crumbs: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  title: PropTypes.string,
  tag: PropTypes.string,
};

export default PageHeaderWithImage;
