import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query CandidateCtaDataQuery {
    allStrapiCandidateCta {
      edges {
        node {
          title
          first_button_text
          first_button_link
          first_button_icon_name
          second_button_text
          second_button_link
          second_button_icon_name
        }
      }
    }
  }
`;

const useCandidateCtaData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiCandidateCta?.edges[0]?.node;
};

export default useCandidateCtaData;
