import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query IntroToOurServicesDataQuery {
    allStrapiIntroToOurService {
      edges {
        node {
          title
          subtitle
          description
          button_text
          button_link
          services {
            id
            title
            description
          }
        }
      }
    }
  }
`;

const useIntroToOurServicesData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiIntroToOurService?.edges[0]?.node;
};

export default useIntroToOurServicesData;
