import { Container } from "@ui/wrapper";
import styled, { phone, tablet, themeGet } from "@styled";

export const SectionWrap = styled.section`
  position: relative;
  overflow: hidden;
  width: 100%;
`;

export const StyledBackground = styled.div`
  background-color: ${({ bgColor }) => themeGet(`colors.${bgColor}`)};
  display: flex;
  width: 100%;
  max-width: 100vw;
  padding-top: 246px;
  padding-bottom: 100px;

  @media (${tablet}) {
    padding-bottom: 70px;
  }

  @media (${phone}) {
    padding-bottom: 60px;
  }
`;

export const StyledTextWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

export const StyledSubtitle = styled.h5`
  font-family: "Sofia Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;

  color: #ffffff;

  @media (${tablet}) {
    font-size: 16px;
    line-height: 14px;
  }

  @media (${phone}) {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const StyledTitle = styled.h1`
  font-family: "Sofia Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 70px;
  color: #fff;
  margin-top: 12px;

  @media (${tablet}) {
    font-size: 49px;
  }

  @media (${phone}) {
    font-size: 35px;
  }

  > span {
    color: #333333;
    background: linear-gradient(
      89.93deg,
      #ffffff 59.79%,
      rgba(196, 196, 196, 0) 94.92%
    );
    border-radius: 10px;
  }
`;
