import React, { useMemo } from "react";
import PropTypes from "prop-types";
// components
import Seo from "@components/seo";
import IntroBlock from "@components/intro-block";
import VideoIntro from "@components/video-intro";
// containers
import Header from "@containers/header";
import CTABlock from "@containers/cta-block";
import BlockWrapper from "@containers/block-wrapper";
// sections
import TextIntro from "./sections/text-intro";
// layouts
import MainLayout from "@layout/main-layout";
// hooks
import {
  useMainVideoPodData,
  useAboutUsHeaderData,
  useAboutUsIntroData,
  useAboutUsMainTextData,
  useClientCtaData,
} from "@hooks";
// utils
import { createStrapiContentUrl } from "@utils/content.utils";
// styles
import { css } from "@styled";

const introBlockStyles = css`
  background-position: 76% 0;
`;

const AboutUsPage = ({ location }) => {
  // hooks
  const aboutUsHeaderData = useAboutUsHeaderData();
  const aboutUsIntroData = useAboutUsIntroData();
  const aboutUsMainTextData = useAboutUsMainTextData();
  const mainVideoPodData = useMainVideoPodData();
  const clientCtaData = useClientCtaData();
  // memo
  const introBlockData = useMemo(
    () => ({
      ...aboutUsIntroData,
      image: createStrapiContentUrl(aboutUsIntroData.image.url),
    }),
    [aboutUsIntroData]
  );

  return (
    <MainLayout location={location}>
      <Seo
        title="About Stream Talent - Industry Recruitment Consultants"
        description="Who are Stream Talent? Australia's leader in Recruitment and Recruitment Marketing. Our marketing technology gives us the tools to find you the best talent for you."
      />
      <Header data={aboutUsHeaderData} />
      <main className="site-wrapper-reveal">
        <IntroBlock
          data={introBlockData}
          gradientType="client"
          styles={introBlockStyles}
        />
        <TextIntro data={aboutUsMainTextData} />
        <BlockWrapper>
          <VideoIntro data={mainVideoPodData} buttonColor="#333333" />
        </BlockWrapper>
        <CTABlock data={clientCtaData} />
      </main>
    </MainLayout>
  );
};

AboutUsPage.propTypes = {
  location: PropTypes.shape({}),
};

export default AboutUsPage;
