export const gradations = [
  {
    id: "service-gradiation-1",
    title: "Discussion",
    desc: "We meet customers in set place to discuss the details about needs and demands before proposing a plan.",
    path: "/about-us",
  },
  {
    id: "service-gradiation-2",
    title: "Concepts & Initatives",
    desc: "Our experts come up with all kinds of ideas and initiatives for delivering the best solutions for IT services chosen.",
    path: "/about-us",
  },
  {
    id: "service-gradiation-3",
    title: "Testing & Trying",
    desc: "After agreeing on the ideas and plans, we will conduct as scheduled and give comments on the results & adaptations.",
    path: "/about-us",
  },
  {
    id: "service-gradiation-4",
    title: "Execute & install",
    desc: "Once the final plan is approved, everything will be conducted according to the agreed contract.",
    path: "/about-us",
  },
];
