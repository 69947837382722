import React from "react";
import styled, { space, themeGet } from "@styled";

export const AuthorMetaWrap = styled(({ mb, mt, mr, ml, ...rest }) => (
  <div {...rest} />
))`
  ${space};
  .block-wrapper {
    display: flex;
    align-items: center;

    span {
      margin-inline-start: 8px;
      color: ${(props) => props.color};
    }
  }
`;

export const ImageWrapper = styled.div`
  width: 40px;
  height: 40px;

  img {
    border-radius: ${themeGet("radii.round")};
    min-height: 40px;
    inset: 0px;
    height: 100%;
    max-width: none;
    width: 40px;
    object-fit: cover;
  }
`;
