import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query PrivacyPolicyDataQuery {
    allStrapiPrivacyPolicy {
      edges {
        node {
          content {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`;

const usePrivacyPolicyData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiPrivacyPolicy.edges[0].node.content.data
    .childMarkdownRemark.html;
};

export default usePrivacyPolicyData;
