import BrandImage1 from "@data/images/partners/mitech-client-logo-01-hover.png";
import BrandImage2 from "@data/images/partners/mitech-client-logo-02-hover.png";
import BrandImage3 from "@data/images/partners/mitech-client-logo-03-hover.png";
import BrandImage4 from "@data/images/partners/mitech-client-logo-04-hover.png";
import BrandImage5 from "@data/images/partners/mitech-client-logo-05-hover.png";
import BrandImage6 from "@data/images/partners/mitech-client-logo-06-hover.png";
import BrandImage7 from "@data/images/partners/mitech-client-logo-07-hover.png";
import BrandImage8 from "@data/images/partners/mitech-client-logo-08-hover.png";
import BrandImage9 from "@data/images/partners/mitech-client-logo-09-hover.png";

export const data = [
  {
    image: { src: BrandImage1 },
  },
  {
    image: { src: BrandImage2 },
  },
  {
    image: { src: BrandImage3 },
  },
  {
    image: { src: BrandImage4 },
  },
  {
    image: { src: BrandImage5 },
  },
  {
    image: { src: BrandImage6 },
  },
  {
    image: { src: BrandImage7 },
  },
  {
    image: { src: BrandImage8 },
  },
  {
    image: { src: BrandImage9 },
  },
];
