import React from "react";
import PropTypes from "prop-types";
// types
import { color } from "@utils/types";
// styles
import {
  SectionWrap,
  StyledTitle,
  StyledSubtitle,
  StyledBackground,
  StyledTextWrapper,
} from "./style";

const PageHeader = ({ data, bgColor }) => {
  return (
    <SectionWrap>
      <StyledBackground bgColor={bgColor}>
        <StyledTextWrapper>
          <StyledSubtitle>{data.subtitle}</StyledSubtitle>
          <StyledTitle dangerouslySetInnerHTML={{ __html: data.title }} />
        </StyledTextWrapper>
      </StyledBackground>
    </SectionWrap>
  );
};

PageHeader.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }),
  bgColor: color,
};

PageHeader.defaultProps = {
  bgColor: "primary",
};

export default PageHeader;
