import image1 from "@data/images/icons/icon-07-60x60.png";
import image2 from "@data/images/icons/icon-08-60x60.png";
import image3 from "@data/images/icons/icon-09-60x60.png";
import image4 from "@data/images/icons/icon-10-60x60.png";
import image5 from "@data/images/icons/icon-11-60x60.png";
import image6 from "@data/images/icons/icon-12-60x60.png";

export const data = [
  {
    id: 1,
    image: { src: image1 },
    title: "IT Design",
    description:
      "We provide the most responsive and functional IT design for companies and businesses worldwide.",
  },
  {
    id: 2,
    image: { src: image2 },
    title: "IT Management",
    description:
      "It’s possible to simultaneously manage and transform information from one server to another.",
  },
  {
    id: 3,
    image: { src: image3 },
    title: "Data Security",
    description:
      "Back up your database, store in a safe and secure place while still maintaining its accessibility.",
  },
  {
    id: 4,
    image: { src: image4 },
    title: "Workforce Management",
    description:
      "Suspe ndisse suscipit sagittis leo sit met condime ntum esti laiolainx bulum iscipit sagittis leo sit met con ndisse suscipit sagittis leo sit met cone su",
  },
  {
    id: 5,
    image: { src: image5 },
    title: "Business Reform",
    description:
      "We provide the most responsive and functional IT design for companies and businesses worldwide.",
  },
  {
    id: 6,
    image: { src: image6 },
    title: "Infrastructure Plan",
    description:
      "Mitech takes into account all conditions and budgets needed for building infrastructure plan..",
  },
];
