import React from "react";
import PropTypes from "prop-types";
// ui
import Image from "@ui/image";
// types
import { ImageType } from "@utils/types";
// styles
import { BrandLogoWrap } from "./style";

const ClientLogo = ({ image, hoverImage, title, layout, ...props }) => {
  return (
    <BrandLogoWrap $layout={layout} {...props}>
      <div>
        <div className="brand-logo__image">
          {image?.src && <Image src={image.src} alt={image?.alt || title} />}
        </div>
        {(layout === 1 || layout === 2) && (
          <div className="brand-logo__image-hover">
            {hoverImage?.src && (
              <Image src={hoverImage.src} alt={hoverImage?.alt || title} />
            )}
          </div>
        )}
      </div>
    </BrandLogoWrap>
  );
};

ClientLogo.propTypes = {
  title: PropTypes.string,
  image: PropTypes.shape(ImageType),
  hoverImage: PropTypes.shape(ImageType),
  layout: PropTypes.oneOf([1, 2, 3, 4]),
};

ClientLogo.defaultProps = {
  layout: 1,
  title: "Brand Logo",
};

export default ClientLogo;
