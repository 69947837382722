import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query CandidateContactUsHeaderDataQuery {
    allStrapiCandidateContactUsHeader {
      edges {
        node {
          left_links {
            text
            url
          }
          right_links {
            text
            url
          }
          button {
            text
            url
          }
        }
      }
    }
  }
`;

const useCandidateContactUsHeaderData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiCandidateContactUsHeader?.edges[0]?.node;
};

export default useCandidateContactUsHeaderData;
