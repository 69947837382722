import React from "react";
import PropTypes from "prop-types";
import cn from "clsx";
// components
import { WidgetTitle } from "@components/widgets";
// ui
import { Wrapper } from "@ui/wrapper";
// utils
import { slugify } from "@utils";
// styles
import { Root, ListItem, ListLink } from "./style";

const RecentPosts = ({ className, data, ...rest }) => {
  return (
    <Root>
      <Wrapper className={cn("recent-posts-widget", className)} {...rest}>
        <WidgetTitle>{data.title}</WidgetTitle>
        <ul>
          {data.posts.map((post) => (
            <ListItem key={slugify(post)}>
              <ListLink path={`/blog/${slugify(post)}`}>
                <i className="icon icon-1 far fa-long-arrow-right"></i>
                <i className="icon icon-2 far fa-long-arrow-right"></i>
                <span>{post}</span>
              </ListLink>
            </ListItem>
          ))}
        </ul>
      </Wrapper>
    </Root>
  );
};

RecentPosts.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    posts: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};

export default RecentPosts;
