import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query AboutUsIntroDataQuery {
    allStrapiAboutUsIntro {
      edges {
        node {
          title
          subtitle
          image {
            url
          }
        }
      }
    }
  }
`;

const useAboutUsIntroData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiAboutUsIntro?.edges[0]?.node;
};

export default useAboutUsIntroData;
