import React from "react";
import PropTypes from "prop-types";
import cn from "clsx";
// components
import WidgetTitle from "@components/widgets/widget-title";
// ui
import { Wrapper } from "@ui/wrapper";
// utils
import { flatDeep, slugify, isBrowser } from "@utils";
// styles
import { StyledTags, StyledTag } from "./style";

const PopularTags = ({ className, data, ...rest }) => {
  if (!isBrowser) return null;

  return (
    <Wrapper className={cn("popular-tags-widget", className)} {...rest}>
      <WidgetTitle>{data.title}</WidgetTitle>
      <StyledTags>
        {data.tags.map((tag) => (
          <StyledTag
            key={slugify(tag)}
            path={`/blog/tags/${slugify(tag)}`}
            isActive={isBrowser && window.location.href.includes(slugify(tag))}
          >
            {tag}
          </StyledTag>
        ))}
      </StyledTags>
    </Wrapper>
  );
};

PopularTags.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};

export default PopularTags;
