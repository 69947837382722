import React from "react";
import PropTypes from "prop-types";
import classnames from "clsx";
// ui
import Anchor from "@ui/anchor";
// utils
import { createColorWithThemeByLocation } from "@utils/content.utils";
// styles
import { StyledMotto } from "./style";

const Motto = ({ text, linkText, path, className, ...rest }) => {
  return (
    <StyledMotto
      className={classnames(className, "motto")}
      $color={createColorWithThemeByLocation()}
      {...rest}
    >
      {text && <>{text}</>}{" "}
      {linkText && (
        <Anchor $layout="underline" $hover={{ layout: 2 }} path={path}>
          {linkText}
        </Anchor>
      )}
    </StyledMotto>
  );
};

Motto.propTypes = {
  text: PropTypes.string,
  linkText: PropTypes.string,
  path: PropTypes.string,
  className: PropTypes.string,
};

Motto.defaultProps = {
  path: "/",
};

export default Motto;
