import React from "react";
import PropTypes from "prop-types";
import cn from "clsx";
// ui
import Image from "@ui/image";
// styles
import { AuthorMetaWrap, ImageWrapper } from "./style";

const AuthorMeta = ({ slug, name, image, className, ...restProps }) => {
  return (
    <AuthorMetaWrap className={cn(className, "author-meta")} {...restProps}>
      <div className="block-wrapper">
        <ImageWrapper>
          <Image src={image} alt={name} />
        </ImageWrapper>
        <span>{name}</span>
      </div>
    </AuthorMetaWrap>
  );
};

AuthorMeta.propTypes = {
  slug: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  className: PropTypes.string,
};

export default AuthorMeta;
