import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
// ui
import { Container, Row, Col } from "@ui/wrapper";
import Button from "@ui/button";
// utils
import { createColorByLocation } from "@utils/content.utils";
// styles
import { StyledSection, StyledHeading, StyledBG } from "./style";

const CTABlock = ({ data }) => {
  return (
    <StyledSection>
      <Container>
        <Row alignItems="center" textAlign={["center", null, null, "left"]}>
          <Col xl={8} lg={7}>
            {data.title && (
              <StyledHeading
                as="h3"
                $color={createColorByLocation()}
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              />
            )}
          </Col>
          <Col xl={4} lg={5} className="text-center">
            <Button
              m="7px"
              color={createColorByLocation()}
              icon={data.first_button_icon_name}
              iconposition="left"
              icondistance="8px"
              iconsize="16px"
              hoverStyle="two"
              path={data.first_button_link}
            >
              {data.first_button_text}
            </Button>
            <Button
              m="7px"
              color={createColorByLocation()}
              variant="outlined"
              icon={data.second_button_icon_name}
              iconposition="left"
              icondistance="8px"
              iconsize="16px"
              path={data.second_button_link}
            >
              {data.second_button_text}
            </Button>
          </Col>
        </Row>
      </Container>
    </StyledSection>
  );
};

CTABlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    first_button_text: PropTypes.string.isRequired,
    first_button_link: PropTypes.string.isRequired,
    first_button_icon_name: PropTypes.string.isRequired,
    second_button_text: PropTypes.string.isRequired,
    second_button_link: PropTypes.string.isRequired,
    second_button_icon_name: PropTypes.string.isRequired,
  }),
};

export default CTABlock;
