import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query BlogHeaderDataQuery {
    allStrapiBlogHeader {
      edges {
        node {
          left_links {
            text
            url
          }
          right_links {
            text
            url
          }
          button {
            text
            url
          }
        }
      }
    }
  }
`;

const useBlogHeaderData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiBlogHeader?.edges[0]?.node;
};

export default useBlogHeaderData;
