import React from "react";
import PropTypes from "prop-types";
// ui
import { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
import Text from "@ui/text";
import Button from "@ui/button";
// containers
import TeamMembersWidget from "@containers/team-members-widget";
// utils
import {
  createColorByLocation,
  createContactUsLinkByLocation,
} from "@utils/content.utils";
// styles
import { TeamWrapper, SectionTitle, TextWrap, ButtonWrap } from "./style";

const TeamMembers = ({ data }) => {
  return (
    <TeamWrapper id="team-members">
      <Container>
        <Row alignItems="center">
          <Col>
            <SectionTitle>
              <Heading
                as="h3"
                child={{ color: createColorByLocation() }}
                dangerouslySetInnerHTML={{ __html: data.title }}
              />
              <TextWrap>
                <Text mt="30px">{data.description}</Text>
              </TextWrap>
            </SectionTitle>
          </Col>
        </Row>
      </Container>
      <TeamMembersWidget tag={data.tag} />
      <Container>
        <Row alignItems="center">
          <Col>
            <SectionTitle>
              <ButtonWrap>
                <Button
                  mr="15px"
                  path={createContactUsLinkByLocation(data.button_link)}
                  color={createColorByLocation()}
                >
                  {data.button_text}
                </Button>
              </ButtonWrap>
            </SectionTitle>
          </Col>
        </Row>
      </Container>
    </TeamWrapper>
  );
};

TeamMembers.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    button_text: PropTypes.string.isRequired,
    button_link: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
  }).isRequired,
};

export default TeamMembers;
