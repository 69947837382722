import styled, { themeGet, device } from "@styled";

export const StyledSection = styled.section`
  background-color: ${themeGet("colors.spring")};
  padding-block: 40px;
  position: relative;
  isolation: isolate;
  overflow: hidden;
  ${device.medium} {
    padding-block: 60px;
  }
  ${device.large} {
    padding-block: 80px;
  }
`;

export const StyledHeading = styled.h3`
  margin-block-end: 10px;
  span {
    color: ${({ $color }) => themeGet(`colors.${$color}`)};
  }
  ${device.large} {
    margin-block-end: 0;
  }
`;
