import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query TeamMembersDataQuery {
    allStrapiTeamMember {
      edges {
        node {
          title
          description
          button_text
          button_link
        }
      }
    }
  }
`;

const useTeamMembersData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiTeamMember?.edges[0]?.node;
};

export default useTeamMembersData;
