import React, { useMemo } from "react";
import PropTypes from "prop-types";
// components
import Seo from "@components/seo";
import IntroBlock from "@components/intro-block";
import VideoIntro from "@components/video-intro";
// containers
import Header from "@containers/header";
import IntroToOurServices from "@containers/intro-to-our-services";
import HowWeWork from "@containers/how-we-work";
import CTABlock from "@containers/cta-block";
import BlockWrapper from "@containers/block-wrapper";
// layouts
import MainLayout from "@layout/main-layout";
// hooks
import {
  useServicesHeaderData,
  useIntroToOurServicesData,
  useHowWeWorkData,
  useClientCtaData,
  useServicesIntroData,
  useMainVideoPodData,
} from "@hooks";
// utils
import { createStrapiContentUrl } from "@utils/content.utils";

const ServicesPage = ({ location }) => {
  // hooks
  const servicesHeaderData = useServicesHeaderData();
  const introToOurServicesData = useIntroToOurServicesData();
  const howWeWorkData = useHowWeWorkData();
  const clientCtaData = useClientCtaData();
  const servicesIntroData = useServicesIntroData();
  const mainVideoPodData = useMainVideoPodData();
  // memo
  const introBlockData = useMemo(
    () => ({
      ...servicesIntroData,
      image: createStrapiContentUrl(servicesIntroData.image.url),
    }),
    [servicesIntroData]
  );

  return (
    <MainLayout location={location}>
      <Seo
        title="Stream Talent - Specialist Recruitment Agency NSW & QLD"
        description="Stream Talents Recruitment services deliver more quality candidates. Our approach engages more candidates with your employer brand to find top quality employees."
      />
      <Header data={servicesHeaderData} />
      <main className="site-wrapper-reveal">
        <IntroBlock data={introBlockData} gradientType="client" />
        <BlockWrapper>
          <IntroToOurServices data={introToOurServicesData} />
        </BlockWrapper>
        <HowWeWork data={howWeWorkData} />
        <VideoIntro data={mainVideoPodData} buttonColor="#333333" />
        {/* TODO: uncomment it in v2 */}
        {/* <Testimonials data={candidateQuotesData} />*/}
        <BlockWrapper onlyTop>
          <CTABlock data={clientCtaData} />
        </BlockWrapper>
      </main>
    </MainLayout>
  );
};
ServicesPage.propTypes = {
  location: PropTypes.shape({}),
};

export default ServicesPage;
