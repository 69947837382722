import styled, { device } from "@styled";
import { allowedTags } from "@utils";
import {
  InputStyles,
  SuccessInputStyles,
  WarningInputStyles,
  ErrorInputStyles,
} from "../style";

export const StyledTextarea = styled("textarea").withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    ![...allowedTags].includes(prop) && defaultValidatorFn(prop),
})`
  ${InputStyles};
  height: 100px;
  padding-top: 15px;
  ${device.large} {
    padding-top: 20px;
    height: 150px;
  }
  ${({ $state, $showState, $showErrorOnly }) =>
    $state === "success" &&
    !!$showState &&
    !$showErrorOnly &&
    SuccessInputStyles};
  ${({ $state, $showState, $showErrorOnly }) =>
    $state === "warning" &&
    !!$showState &&
    !$showErrorOnly &&
    WarningInputStyles};
  ${({ $state, $showState, $showErrorOnly }) =>
    $state === "error" && !!$showState && !!$showErrorOnly && ErrorInputStyles};
`;
