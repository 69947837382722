import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query ClientIndustriesDataQuery {
    allStrapiClientIndustry {
      edges {
        node {
          id
          title
          image {
            url
          }
          description
          icon_name
          intro_title
          intro_subtitle
          intro_content
        }
      }
    }
  }
`;

const useClientIndustriesData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiClientIndustry?.edges;
};

export default useClientIndustriesData;
