import React, { useEffect } from "react";
import PropTypes from "prop-types";
// utils
import { insertWidgetToDOM } from "@utils/widgets.utils";
// styles
import { theme } from "@styled/theme";

const IndustryJobsWidget = ({ tag }) => {
  useEffect(() => {
    insertWidgetToDOM(
      window?.refari?.load(
        "RefariLatestJobs",
        function (Widget, additionalData) {
          new Widget({
            node: document.getElementById("refLatestJobs"), // 1. [required] widget container element
            apiKey: "2806cceae3737c305e87cd5a67af9e6b63ecbc7e", // 2. [required] agency's unique public API key
            number: 6, // 3. [optional] number of jobs in list to display. Default - 6. Available amount - from 2 to 12
            jobsInARow: 3, // 4. [optional] number of jobs in slider to display. Default - 3. Available values - 1,2,3,4
            allJobsButton: true, // 5. [optional] show view all jobs button or not. Default - true. Available values - true,false
            widgetMaxWidth: null, // 6. [optional] field, set up widget max-width by default no max-width
            themePalette: {
              // 7. [optional] theme customizations
              primary1Color: theme.colors.candidate,
              accent1Color: "",
              textColor: "",
              textColorContent: "",
              widgetBackgroundColor: "",
              widgetTileBackgroundColor: "",
              hoverTextColor: "",
              hoverIconColor: "",
            },
            elements: {
              // 8. [optional] elements customizations
              buttonBorderRadius: 2, // Number or String (2 or '2px' or '2%')
              tileBorderRadius: 0, // Number (2)
            },
            category: "", // 9. [optional] will add additional filter for search jobs by category takes next params: ['ID', 'ID'], 'ID', {ID}%2C{ID}%2C{ID}%2C{ID}
            worktype: "", // 10. [optional] will add additional filter for search jobs by worktype takes next params: ['ID', 'ID'], 'ID', {ID}%2C{ID}%2C{ID}%2C{ID}
            location: "", // 11. [optional] will add additional filter for search jobs by location takes next params: ['ID', 'ID'], 'ID', {ID}%2C{ID}%2C{ID}%2C{ID}
            search: "", // 12. [optional] will add additional filter for search jobs by search takes next params: ['search', 'search'], 'search', {search}%7C{search}
            tags: tag || "", // 13. [optional] will add additional filter for search jobs by job tags takes next params: ['tag', 'tag'], 'tag', {tag}%2C{tag}%2C{tag}
            recruiter: "", // 14. [optional] will add additional filter for search jobs by recruiter takes next params: ['ID', 'ID'], 'ID', {ID}%2C{ID}%2C{ID}%2C{ID}
            hoverBehavior: true, // 15. [optional] will add hover behavior for job items by default use true
            showPagination: true, // 16. [optional] will show pagination dots by default use true
            paginationStyle: 1, // 17. [optional] will show pagination arrow for value 1 and pagination dots for value 2 (by default use 1)
            overflowRight: false, // 18. [optional] will show one overflow job on right side if set to true (by default use false)
            overflowLeft: false, // 19. [optional] will show one overflow job on left side if set to true (by default use false)
            overflowFade: false, // 20. [optional] will show overflow fade behaviour on both horizontal sides (by default use false)
            paginationLeftOffset: "50%", // 21. [optional] will add margin left to pagination buttons - Number or String (50 or '50px' or '50%')
            jobsLeftOffset: 0, // 22. [optional] will add margin left to jobs slider - Number or String (0 or '0px' or '0%')
            viewJobsButtonLeftOffset: "50%", // 23. [optional] will add margin left to view jobs button - Number or String (50 or '50px' or '50%')
            autoRotate: true, // 24. [optional] will auto rotate the job slider (by default use false)
            autoRotateTimer: 5000, // 25. [optional] will add auto rotate timer in milliseconds - (by default use 5000)
            showScrollBar: true, // 26. [optional] show vertical scrollbar, will work with scrollingPostition as 'vertical'
            buttonStyleInline: false, // 27. [optional] show action buttons as inline, will work with scrollingPostition as 'vertical'
            showTileBorder: true, // 28. [optional] show tile border or not, will work with scrollingPostition as 'vertical'
            showJobSeparator: false, // 29. [optional] show job separator line between tiles
            emptyJobBoardTitle: "Create a job alert", // 30. [optional] change "Create a job alert" title when there are no jobs on the board
            latestJobDesign: 2, // 31. [optional] change layout design for latest job widget
            // baseUrl: ''                                // 32. [required] field, set up widget base url
          });
        }
      ),
      document.querySelector("#industry-jobs-widget"),
      "industry-jobs-widget"
    );
  }, [tag]);

  return (
    <div id="refLatestJobs">
      <noscript>
        You need to enable JavaScript to run this application.
      </noscript>
    </div>
  );
};

IndustryJobsWidget.propTypes = {
  tag: PropTypes.string.isRequired,
};

export default IndustryJobsWidget;
