import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query MainVideoPodDataQuery {
    allStrapiMainVideoPod {
      edges {
        node {
          title
          button_text
          video_url
          image {
            url
          }
        }
      }
    }
  }
`;

const useMainVideoPodData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiMainVideoPod?.edges[0]?.node;
};

export default useMainVideoPodData;
