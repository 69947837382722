import image1 from "@data/images/icons/icon-01-100x108.png";
import image2 from "@data/images/icons/icon-02-100x108.png";
import image3 from "@data/images/icons/icon-03-100x108.png";
import image4 from "@data/images/icons/icon-04-100x108.png";
import image5 from "@data/images/icons/icon-05-100x108.png";
import image6 from "@data/images/icons/icon-06-100x108.png";

export const data = [
  {
    id: 1,
    image: { src: image1 },
    title: "IT Design",
    description:
      "We provide the most responsive and functional IT design for companies and businesses worldwide.",
  },
  {
    id: 2,
    image: { src: image2 },
    title: "IT Management",
    description:
      "It’s possible to simultaneously manage and transform information from one server to another.",
  },
  {
    id: 3,
    image: { src: image3 },
    title: "Data Security",
    description:
      "Back up your database, store in a safe and secure place while still maintaining its accessibility.",
  },
  {
    id: 4,
    image: { src: image4 },
    title: "Business Reform",
    description:
      "We propose feasible & practical plans for successfully transform businesses based on their needs.",
  },
  {
    id: 5,
    image: { src: image5 },
    title: "Infrastructure Plan",
    description:
      "Mitech takes into account all conditions and budgets needed for building infrastructure plan.",
  },
  {
    id: 6,
    image: { src: image6 },
    title: "Firewall Advancement",
    description:
      "Enhancing the strength and security of firewalls to protect online data from malicious sources.",
  },
];
