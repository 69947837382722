import React from "react";
import PropTypes from "prop-types";
// components
import ClientLogo from "@components/client-logo";
// ui
import { Container } from "@ui/wrapper";
import SwiperSlider, { SwiperSlide } from "@ui/swiper";
// utils
import { createStrapiContentUrl } from "@utils/content.utils";
// types
import { ImagePropType } from "@utils/types";
// styles
import { SectionWrap } from "./style";

const slider = {
  slidesPerView: 6,
  loop: true,
  speed: 1000,
  breakpoints: {
    320: {
      slidesPerView: 2,
    },
    575: {
      slidesPerView: 3,
    },
    767: {
      slidesPerView: 4,
    },
    991: {
      slidesPerView: 5,
    },
    1499: {
      slidesPerView: 6,
    },
  },
};

const Partners = ({ data }) => {
  return (
    <SectionWrap>
      <Container>
        {Array.isArray(data) && data.length && (
          <SwiperSlider options={slider} vAlign="center">
            {data.map((item, i) => (
              <SwiperSlide key={item.id}>
                <ClientLogo
                  layout={1}
                  title={`Client logo ${i + 1}`}
                  image={{
                    src: createStrapiContentUrl(item.image.url),
                  }}
                  hoverImage={{
                    src: createStrapiContentUrl(item.hover_image.url),
                  }}
                />
              </SwiperSlide>
            ))}
          </SwiperSlider>
        )}
      </Container>
    </SectionWrap>
  );
};

Partners.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      image: ImagePropType,
      hover_image: ImagePropType,
    })
  ).isRequired,
};

export default Partners;
