import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query CandidateSearchBarDataQuery {
    allStrapiCandidateSearchBar {
      edges {
        node {
          title
          subtitle
        }
      }
    }
  }
`;

const useCandidateSearchBarData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiCandidateSearchBar?.edges[0]?.node;
};

export default useCandidateSearchBarData;
