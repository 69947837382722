import styled, { tablet, phone } from "@styled";

export const SectionWrap = styled.section`
  max-width: 789px;
  width: 100%;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 40px;

  @media (${tablet}) {
    max-width: 561px;
  }

  @media (${phone}) {
  }
`;

export const StyledContent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  margin-top: 50px;

  li {
    list-style: inside;

    p {
      display: inline;
    }
  }

  @media (${tablet}) {
    font-size: 18px;
    margin-top: 35px;
  }

  @media (${phone}) {
    font-size: 24px;
    margin-top: 50px;
  }

  color: #333333;
`;
