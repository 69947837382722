import styled, { device, themeGet } from "@styled";

export const StyledAuthor = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledAvatar = styled.figure`
  max-width: 170px;
  flex-basis: 170px;
  text-align: center;
  margin-block-end: 20px;
  width: 100px;
  height: 100px;

  @media (max-width: 576px) {
    margin-bottom: 60px;
  }

  img {
    border-radius: ${themeGet("radii.round")};
    margin-block-end: 22px !important;
    min-height: 100px;
    inset: 0px;
    height: 100%;
    max-width: none;
    width: 100px;
    object-fit: cover;
  }
`;

export const StyledInfo = styled.div`
  max-width: 100%;
  flex-basis: 100%;

  ${device.small} {
    max-width: calc(100% - 170px);
    flex-basis: calc(100% - 170px);
  }
`;

export const StyledBio = styled.p`
  margin-block-start: 15px;
  font-size: 15px;
  color: ${themeGet("colors.heading")};
`;
