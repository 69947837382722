import iconOne from "@data/images/icons/icon-07-60x60.png";
import iconTwo from "@data/images/icons/icon-08-60x60.png";
import iconThree from "@data/images/icons/icon-09-60x60.png";
import iconFour from "@data/images/icons/icon-10-60x60.png";

export const data = [
  {
    id: "box-icon-3-1",
    title: "Quality Assurance System",
    description:
      "Our service offerings enhance customer experience throughout secure & highly functional end-to-end warranty management.",
    path: "/",
    icon: { src: iconOne },
  },
  {
    id: "box-icon-3-2",
    title: "Accurate Testing Processes",
    description:
      "Develop and propose product improvements through periodical and accurate testing, repairing & refining every version.",
    path: "/",
    icon: { src: iconTwo },
  },
  {
    id: "box-icon-3-3",
    title: "Smart API Generation",
    description:
      "Develop and propose product improvements through periodical and accurate testing, repairing & refining every version.        ",
    path: "/",
    icon: { src: iconThree },
  },
  {
    id: "box-icon-3-4",
    title: "Infrastructure Integration Technology",
    description:
      "At Mitech, we have a holistic and integrated approach towards core modernization to experience technological evolution.        ",
    path: "/",
    icon: { src: iconFour },
  },
];
