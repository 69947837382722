import React from "react";
import styled, { themeGet, space } from "@styled";
import { typography } from "styled-system";

export const TagWrapper = styled(({ mb, mt, mr, ml, ...rest }) => (
  <div {...rest} />
))`
  display: flex;
  align-items: center;
  overflow-x: auto;
  ${space};
  a {
    color: ${themeGet("colors.silver")};
    font-size: 13px;
    padding-inline-end: 3px;
  }
  .icon {
    font-size: 18px;
    padding-inline-end: 9px;
    color: ${themeGet("colors.text")};
  }
`;

export const SecondaryTagsWrap = styled(({ mb, mt, textAlign, ...rest }) => (
  <div {...rest} />
))`
  ${space};
  ${typography};
  a {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: ${themeGet("colors.secondary")};
    text-transform: uppercase;
    &:not(:last-child) {
      margin-inline-end: 5px;
    }
  }
`;
