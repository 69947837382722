import styled, { themeGet, tablet } from "@styled";

export const Root = styled.section`
  background-color: ${themeGet("colors.gray.100")};
  padding: 40px 0;
  padding-top: 200px;

  @media (${tablet}) {
    padding-top: 100px;
  }
`;

export const StyledBox = styled.div`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 130%;
    left: 50%;
    bottom: 0;
    width: 4px;
    height: 150px;
    transform: translateY(-50%) rotate(90deg);
    background-color: ${themeGet("colors.secondary")};
  }
`;

export const Content = styled.div`
  padding-top: 40px;

  @media (${tablet}) {
    padding-top: 20px;
  }

  li {
    list-style: inside;
  }
  h5 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  p {
    margin-top: 10px;
  }
  a {
    color: #0d6efd;
  }
`;
