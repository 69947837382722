import React, { useMemo } from "react";
import PropTypes from "prop-types";
// components
import Seo from "@components/seo";
import IntroBlock from "@components/intro-block";
import VideoIntro from "@components/video-intro";
import IndustryTextIntro from "@components/industry-text-intro";
import IndustryDescription from "@components/industry-description";
// containers
import Header from "@containers/header";
import TeamMembers from "@containers/team-members";
import CTABlock from "@containers/cta-block";
import BlockWrapper from "@containers/block-wrapper";
import TeamMembersWidget from "@containers/team-members-widget";
import Resources from "@containers/resources";
// layouts
import MainLayout from "@layout/main-layout";
// hooks
import {
  useClientIndustryHeaderData,
  useMainVideoPodData,
  useIndustryDescriptionData,
  useClientIndustryIntroData,
  useClientCtaData,
  useTeamMembersData,
  useSearchURLParams,
  useResourcesData,
  usePostsPreviewData,
} from "@hooks";
// utils
import { isBrowser } from "@utils";
import { createStrapiContentUrl } from "@utils/content.utils";
// types
import { IndustryType } from "@utils/types";

const ClientIndustryTemplate = ({ pageContext, location }) => {
  // hooks
  const clientIndustryHeaderData = useClientIndustryHeaderData();
  const industryDescriptionData = useIndustryDescriptionData();
  const mainVideoPodData = useMainVideoPodData();
  const clientIndustryIntroData = useClientIndustryIntroData();
  const clientCtaData = useClientCtaData();
  const teamMembersData = useTeamMembersData();
  const resourcesData = useResourcesData();
  const postsPreviewData = usePostsPreviewData();
  const teamMemberValue = useSearchURLParams("consultant");
  // memo
  const industryIntroData = useMemo(
    () => ({
      title: pageContext.industry.intro_title,
      subtitle: pageContext.industry.intro_subtitle,
      content: pageContext.industry.intro_content,
    }),
    [pageContext.industry]
  );

  const pageIntroData = useMemo(
    () => ({
      title: pageContext.industry.page_title,
      subtitle: clientIndustryIntroData.subtitle,
      image: createStrapiContentUrl(pageContext.industry.image.url),
    }),
    [clientIndustryIntroData, pageContext.industry]
  );

  const industryDescriptionFormattedData = useMemo(() => {
    const linkUrl = industryDescriptionData.link_url;

    return {
      ...industryDescriptionData,
      link_url: isBrowser ? `${window.location}${linkUrl}${linkUrl}` : linkUrl,
      description: pageContext.industry.clients_we_service_text,
      roles: pageContext.industry.roles_we_recruit,
    };
  }, [
    industryDescriptionData,
    pageContext.industry.clients_we_service_text,
    pageContext.industry.roles_we_recruit,
  ]);

  const teamMembersFormattedData = useMemo(
    () => ({ ...teamMembersData, tag: pageContext.industry.team_members_tag }),
    [pageContext.industry.team_members_tag, teamMembersData]
  );

  const resourcesFormattedData = useMemo(
    () => ({
      ...resourcesData,
      posts: postsPreviewData.map((post) => post.node),
    }),
    [resourcesData, postsPreviewData]
  );

  if (!isBrowser) return null;

  if (!!teamMemberValue) {
    return (
      <MainLayout location={location}>
        <Seo title="Team member" />
        <main className="site-wrapper-reveal">
          <TeamMembersWidget tag={pageContext.industry.team_members_tag} />
          <BlockWrapper onlyTop>
            <CTABlock data={clientCtaData} />
          </BlockWrapper>
        </main>
      </MainLayout>
    );
  }

  return (
    <MainLayout location={location}>
      <Seo
        title={pageContext.industry.meta_title}
        description={pageContext.industry.meta_description}
      />
      <Header data={clientIndustryHeaderData} />
      <main className="site-wrapper-reveal">
        <IntroBlock data={pageIntroData} gradientType="client" />
        <IndustryTextIntro data={industryIntroData} />
        <IndustryDescription data={industryDescriptionFormattedData} />
        <TeamMembers data={teamMembersFormattedData} />
        <VideoIntro data={mainVideoPodData} buttonColor="#333333" />
        {/* TODO: uncomment it in v2 */}
        {/* <Testimonials data={clientQuotesData} />*/}
        <BlockWrapper onlyTop>
          <CTABlock data={clientCtaData} />
        </BlockWrapper>
        <BlockWrapper>
          <Resources data={resourcesFormattedData} />
        </BlockWrapper>
      </main>
    </MainLayout>
  );
};

ClientIndustryTemplate.propTypes = {
  pageContext: PropTypes.shape({
    industry: IndustryType.isRequired,
  }),
  location: PropTypes.shape({}),
};

export default ClientIndustryTemplate;
