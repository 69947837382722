import React from "react";
import PropTypes from "prop-types";
// components
import Industry from "@components/industry";
import SectionTitle from "@components/section-title";
// ui
import { Container, Row, Col } from "@ui/wrapper";
import Button from "@ui/button";
// utils
import { slugify, isBrowser } from "@utils";
import { createColorByLocation } from "@utils/content.utils";
// styles
import { IndustriesWrapper, SectionBottom } from "./style";

const Industries = ({ data, ...rest }) => {
  if (!isBrowser) return null;

  return (
    <IndustriesWrapper {...rest} id="industries">
      <Container>
        {data?.title && data?.subtitle && (
          <SectionTitle
            mb={["30px", null, "40px"]}
            subtitle={data?.subtitle}
            title={data?.title}
          />
        )}
        {Array.isArray(data?.industries) && (
          <Row>
            {data.industries.map((item) => (
              <Col lg={4} md={6} key={item.id}>
                <Industry
                  id={item.id}
                  title={item.title}
                  desc={item.description}
                  icon={item.icon_name}
                  path={`/${window.location.pathname.replace(
                    /\//g,
                    ""
                  )}/industries/${slugify(item.title)}`}
                />
              </Col>
            ))}
          </Row>
        )}
        <SectionBottom>
          <Button
            m="10px"
            path={data?.first_button_link}
            color={createColorByLocation()}
          >
            {data?.first_button_text}
          </Button>
          <Button
            m="10px"
            variant="outlined"
            path={data?.second_button_link}
            color={createColorByLocation()}
          >
            {data?.second_button_text}
          </Button>
        </SectionBottom>
      </Container>
    </IndustriesWrapper>
  );
};

Industries.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    first_button_text: PropTypes.string.isRequired,
    first_button_link: PropTypes.string.isRequired,
    second_button_text: PropTypes.string.isRequired,
    second_button_link: PropTypes.string.isRequired,
    industries: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        icon_name: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
};

export default Industries;
