import React from "react";
import PropTypes from "prop-types";
// ui
import Social, { SocialLink } from "@ui/social";
import Image from "@ui/image";
// utils
import { createColorByLocation } from "@utils/content.utils";
// types
import { ImageType, SocialType } from "@utils/types";
// styles
import {
  TeamMemberWrap,
  TeamMemberInner,
  TeamMemberImage,
  TeamMemberSocialWrap,
  TeamMemberInfo,
  TeamMemberName,
  StyledLink,
} from "./style";

const TeamMember = ({
  id,
  image,
  name,
  designation,
  socials,
  layout,
  onSocialClick,
}) => {
  return (
    <TeamMemberWrap $layout={layout}>
      <TeamMemberInner>
        <TeamMemberImage>
          {image?.src && (
            <StyledLink to={`/team-members/${id}`}>
              <Image src={image.src} alt={image?.alt || name} />
            </StyledLink>
          )}

          <TeamMemberSocialWrap $bgColor={createColorByLocation()}>
            <Social
              color="light"
              variant="texted"
              tooltip={true}
              tooltip_bg="secondary"
              hover={{ color: "#fff" }}
              space="20px"
              size="small"
            >
              {socials?.map(({ id, icon, link, title }) => (
                <SocialLink
                  key={id}
                  title={title}
                  path={link}
                  onClick={onSocialClick}
                >
                  <i className={icon}></i>
                </SocialLink>
              ))}
            </Social>
          </TeamMemberSocialWrap>
        </TeamMemberImage>
        <TeamMemberInfo $layout={layout}>
          {name && <TeamMemberName $layout={layout}>{name}</TeamMemberName>}
          {designation && <p>{designation}</p>}
        </TeamMemberInfo>
      </TeamMemberInner>
    </TeamMemberWrap>
  );
};

TeamMember.propTypes = {
  id: PropTypes.string,
  image: PropTypes.shape(ImageType),
  name: PropTypes.string,
  designation: PropTypes.string,
  socials: PropTypes.arrayOf(PropTypes.shape(SocialType)),
  layout: PropTypes.oneOf([1, 2, 3]),
  onSocialClick: PropTypes.func,
};

TeamMember.defaultProps = {
  layout: 1,
  social: [],
};

export default TeamMember;
