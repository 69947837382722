import React, { useEffect } from "react";
// utils
import { insertWidgetToDOM } from "@utils/widgets.utils";
// styles
import { theme } from "@styled/theme";

const SearchBarWidget = () => {
  useEffect(() => {
    insertWidgetToDOM(
      window.refari.load("RefariSearchBar", function (Widget, additionalData) {
        new Widget({
          node: document.getElementById("refSearchBar"), // 1. [required] widget container element
          apiKey: "2806cceae3737c305e87cd5a67af9e6b63ecbc7e", // 2. [required] agency's unique public API key
          optionalFields: ["category", "location"], // 3. [optional] fields, by default shows only keyword search field
          themePalette: {
            // 4. [optional] theme customizations
            primary1Color: theme.colors.candidate,
            accent1Color: "",
            textColor: "",
          },
          elements: {
            // 5. [optional] elements customizations
            buttonBorderRadius: 0, // Number or String (2 or '2px' or '2%')
          },
          hoverColour: "", // 6. [optional] color for hover
          hoverTextColour: "", // 7. [optional] text color for hover
          searchDesign: 3, // 8. [optional] change design for search widget (Possible values : 1 for default layout, 2 and 3 for another layout)
          showSearchIcon: true, // 9. [optional] show only search icon instead of button, default value is false
          boxHeight: 55, // 10. [optional] set the box height of the search tiles (by default 55)
          textAlign: "left", // 11. [optional] set the search tiles text alignment (Possible values : 'center', 'left', 'right')
          // baseUrl: ''                                // 12. [required] field, set up widget base url
        });
      }),
      document.querySelector("#search-bar-widget"),
      "search-bar-widget"
    );
  }, []);

  return (
    <div id="refSearchBar">
      <noscript>
        You need to enable JavaScript to run this application.
      </noscript>
    </div>
  );
};

export default SearchBarWidget;
