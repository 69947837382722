import React from "react";
import PropTypes from "prop-types";
// ui
import { Container, Row, Col } from "@ui/wrapper";
// components
import ClientContactUsForm from "@components/client-contact-us-form";
import CandidateContactUsForm from "@components/candidate-contact-us-form";
// utils
import {
  createColorByLocation,
  createElementTypeByLocation,
} from "@utils/content.utils";
// styles
import { StyledSection, LeftBox, StyledHeading, StyledDesc } from "./style";

const ContactUsWrapper = ({ data }) => {
  return (
    <StyledSection>
      <Container>
        <Row>
          <Col lg={5}>
            <LeftBox>
              <StyledHeading
                as="h3"
                $color={createColorByLocation()}
                dangerouslySetInnerHTML={{
                  __html: data?.title,
                }}
              />
              <StyledDesc
                dangerouslySetInnerHTML={{
                  __html: data?.description,
                }}
              />
            </LeftBox>
          </Col>
          <Col lg={7}>
            {createElementTypeByLocation(
              <CandidateContactUsForm />,
              <ClientContactUsForm />
            )}
          </Col>
        </Row>
      </Container>
    </StyledSection>
  );
};

ContactUsWrapper.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default ContactUsWrapper;
