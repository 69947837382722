import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query ClientIndustryIntroDataQuery {
    allStrapiClientIndustryIntro {
      edges {
        node {
          title
          subtitle
        }
      }
    }
  }
`;

const useClientIndustryIntroData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiClientIndustryIntro?.edges[0]?.node;
};

export default useClientIndustryIntroData;
