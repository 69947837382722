import image1 from "@data/images/icons/icon-01-100x98.png";
import image2 from "@data/images/icons/icon-04-100x98.png";
import image3 from "@data/images/icons/icon-06-100x98.png";

export const data = [
  {
    id: 1,
    image: { src: image1 },
    title: "IT Design",
    description:
      "We provide the most responsive and functional IT design for companies and businesses worldwide.",
  },
  {
    id: 2,
    image: { src: image2 },
    title: "IT Management",
    description:
      "It’s possible to simultaneously manage and transform information from one server to another.",
  },
  {
    id: 3,
    image: { src: image3 },
    title: "Data Security",
    description:
      "Back up your database, store in a safe and secure place while still maintaining its accessibility.",
  },
];
