import React, { useMemo } from "react";
import PropTypes from "prop-types";
import cn from "clsx";
// styles
import {
  PaginationWrap,
  PaginationList,
  PaginationItem,
  PaginationLink,
} from "./style";

const Pagination = ({
  currentPage,
  numberOfPages,
  rootPage,
  className,
  paginationLink,
  ...rest
}) => {
  // memo
  const isFirst = useMemo(() => currentPage === 1, [currentPage]);

  const isLast = useMemo(
    () => currentPage === numberOfPages,
    [currentPage, numberOfPages]
  );

  const previousPage = useMemo(
    () =>
      currentPage - 1 === 1
        ? rootPage
        : paginationLink + (currentPage - 1).toString(),
    [currentPage, rootPage, paginationLink]
  );

  const nextPage = useMemo(
    () => paginationLink + (currentPage + 1).toString(),
    [paginationLink, currentPage]
  );

  return (
    <PaginationWrap className={cn(className, "pagination")} {...rest}>
      <PaginationList>
        {isFirst ? (
          <PaginationItem>
            <PaginationLink disabled={true} path={previousPage}>
              Prev
            </PaginationLink>
          </PaginationItem>
        ) : (
          <PaginationItem classname="prev">
            <PaginationLink path={previousPage}>Prev</PaginationLink>
          </PaginationItem>
        )}
        {Array.from({ length: numberOfPages }, (_, i) =>
          currentPage === i + 1 ? (
            <PaginationItem key={`page-number-${i + 1}`}>
              <PaginationLink
                active="true"
                path={`${rootPage}/${i === 0 ? "" : "page/" + (i + 1)}`}
              >
                {i + 1}
              </PaginationLink>
            </PaginationItem>
          ) : (
            <PaginationItem key={`page-number-${i + 1}`}>
              <PaginationLink
                path={`${i === 0 ? rootPage : paginationLink + (i + 1)}`}
              >
                {i + 1}
              </PaginationLink>
            </PaginationItem>
          )
        )}
        {isLast ? (
          <PaginationItem>
            <PaginationLink disabled={true} path={nextPage}>
              Next
            </PaginationLink>
          </PaginationItem>
        ) : (
          <PaginationItem className="next">
            <PaginationLink path={nextPage}>Next</PaginationLink>
          </PaginationItem>
        )}
      </PaginationList>
    </PaginationWrap>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  numberOfPages: PropTypes.number,
  rootPage: PropTypes.string,
  className: PropTypes.string,
  paginationLink: PropTypes.string,
};

Pagination.defaultProps = {
  paginationLink: "/blog/page/",
};

export default Pagination;
