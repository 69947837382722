import React, { useState, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// ui
import { Row, Col } from "@ui/wrapper";
import { FormGroup, Input, Textarea } from "@ui/form-elements";
import Button from "@ui/button";
import Alert from "@ui/alert";
// components
import SelectField from "@components/select-field";
// hooks
import {
  useClientIndustriesData,
  useIntroToOurServicesData,
  useWindowSize,
} from "@hooks";
// api
import { sendClientContactUsData } from "src/api/client";
// constants
import { FORM_STATUSES } from "@constants/form.constants";
// utils
import { slugify, hasKey } from "@utils";
// schemas
import { clientContactUsSchema } from "src/schemas/client-contact-us.schema";

const ClientContactUsForm = () => {
  // state
  const [formStatus, setFormStatus] = useState({
    message: "",
    status: "",
  });
  // hooks
  const introToOurServicesData = useIntroToOurServicesData();
  const clientIndustries = useClientIndustriesData();
  const { isMobile } = useWindowSize();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(clientContactUsSchema),
  });
  // memo
  const servicesOptions = useMemo(
    () =>
      introToOurServicesData.services.map((service) => ({
        value: slugify(service.title),
        label: service.title,
      })),
    [introToOurServicesData.services]
  );

  const industriesOptions = useMemo(
    () =>
      clientIndustries.map((industry) => ({
        value: slugify(industry.node.title),
        label: industry.node.title,
      })),
    [clientIndustries]
  );

  const onSubmit = async (data) => {
    const response = await sendClientContactUsData({
      ...data,
      industry: data.industry.label,
      service: data.service.label,
    });

    setFormStatus(response);
    reset({ name: "", email: "", message: "", phone: "" });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          {formStatus.status && formStatus.message && (
            <Alert
              variant={
                formStatus.status === FORM_STATUSES.ERROR ? "danger" : "success"
              }
              mb="30px"
              id="status"
            >
              <i
                className={`icon far ${
                  formStatus.status === FORM_STATUSES.ERROR
                    ? "fa-times-circle"
                    : "fa-check-circle"
                }`}
              ></i>
              {formStatus.message}
            </Alert>
          )}
        </Col>
      </Row>
      <Row gutters={{ lg: 20 }}>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              id="name"
              placeholder="Name *"
              feedbackText={errors?.name?.message}
              state={hasKey(errors, "name") ? "error" : "success"}
              showState={!!hasKey(errors, "name")}
              {...register("name")}
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Input
              type="text"
              id="email"
              placeholder="Email *"
              feedbackText={errors?.email?.message}
              state={hasKey(errors, "email") ? "error" : "success"}
              showState={!!hasKey(errors, "email")}
              {...register("email")}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <FormGroup mb="20px">
            <Input
              type="tel"
              id="phone"
              placeholder="Phone *"
              feedbackText={errors?.phone?.message}
              state={hasKey(errors, "phone") ? "error" : "success"}
              showState={!!hasKey(errors, "phone")}
              {...register("phone")}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <FormGroup mb="30px">
            <Textarea
              id="message"
              placeholder="Please describe what you need. *"
              feedbackText={errors?.message?.message}
              state={hasKey(errors, "message") ? "error" : "success"}
              showState={!!hasKey(errors, "message")}
              hover={1}
              {...register("message")}
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Controller
              control={control}
              name="service"
              render={({ field: { onChange, value, ref } }) => (
                <SelectField
                  ref={ref}
                  placeholder="Services *"
                  value={value}
                  isError={!!hasKey(errors, "service")}
                  feedbackText={errors?.service?.label?.message}
                  state={hasKey(errors, "service") ? "error" : "success"}
                  showState={!!hasKey(errors, "service")}
                  onChange={(val) => onChange(val)}
                  options={servicesOptions}
                />
              )}
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup mb="20px">
            <Controller
              control={control}
              name="industry"
              render={({ field: { onChange, value, ref } }) => (
                <SelectField
                  ref={ref}
                  placeholder="Industries *"
                  value={value}
                  isError={!!hasKey(errors, "industry")}
                  feedbackText={errors?.industry?.label?.message}
                  state={hasKey(errors, "industry") ? "error" : "success"}
                  showState={!!hasKey(errors, "industry")}
                  onChange={(val) => onChange(val)}
                  options={industriesOptions}
                />
              )}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Button type="submit" fullwidth={isMobile}>
            Send Message
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default ClientContactUsForm;
