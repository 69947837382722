import React from "react";
import PropTypes from "prop-types";
import cn from "clsx";
// ui
import Anchor from "@ui/anchor";
// utils
import { slugify } from "@utils";
// styles
import { TagWrapper, SecondaryTagsWrap } from "./style";

const Tags = ({ tags, className, variant, ...restProps }) => {
  if (variant === "secondary") {
    return (
      <SecondaryTagsWrap className={cn(className, "categories")} {...restProps}>
        {tags.map((tag, i) => (
          <Anchor path={`/blog/tags/${slugify(tag)}`} key={tag}>
            {i === tags.length - 1 ? tag : `${tag}, `}
          </Anchor>
        ))}
      </SecondaryTagsWrap>
    );
  }

  return (
    <TagWrapper className={cn(className, "tags")} {...restProps}>
      <i className="fa fa-tags icon"></i>
      {tags.map((tag, i) => (
        <Anchor path={`/blog/tags/${slugify(tag)}`} key={tag}>
          {i === tags.length - 1 ? tag : `${tag}, `}
        </Anchor>
      ))}
    </TagWrapper>
  );
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary"]),
};

Tags.defaultProps = {
  variant: "primary",
};

export default Tags;
