import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query TermsAndConditionsDataQuery {
    allStrapiTermsAndCondition {
      edges {
        node {
          content {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`;

const useTermsAndConditionsData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiTermsAndCondition.edges[0].node.content.data
    .childMarkdownRemark.html;
};

export default useTermsAndConditionsData;
