import { useEffect, useMemo, useState } from "react";
// styles
import { breakpoints } from "@styled";
// utils
import { convertBreakpointToNumber, throttle } from "@utils/helpers";

const maxWidth = {
  mobile: convertBreakpointToNumber(breakpoints[1]),
  tablet: convertBreakpointToNumber(breakpoints[2]),
  laptop: convertBreakpointToNumber(breakpoints[3]),
};

const throttleTime = 1000;

const useWindowSize = () => {
  // state
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  const isMobile = useMemo(
    () => size.width < maxWidth.mobile && size.width > 0,
    [size.width]
  );
  const isTablet = useMemo(
    () => size.width >= maxWidth.mobile && size.width < maxWidth.tablet,
    [size.width]
  );
  const isLaptop = useMemo(
    () => size.width >= maxWidth.tablet && size.width < maxWidth.laptop,
    [size.width]
  );

  const updateSize = throttle(() => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, throttleTime);

  useEffect(() => {
    if (typeof window !== "undefined") {
      updateSize();

      window.addEventListener("resize", updateSize);

      return () => {
        window.removeEventListener("resize", updateSize);
      };
    }
  }, []);

  return {
    wndSize: size,
    isMobile,
    isTablet,
    isLaptop,
  };
};

export default useWindowSize;
