import styled, { css } from "@styled";

export const SectionWrap = styled.section`
  padding-block: 60px;
  ${({ onlyTop }) =>
    onlyTop &&
    css`
      padding-bottom: 0;
    `}
  ${({ onlyBottom }) =>
    onlyBottom &&
    css`
      padding-top: 0;
    `}
`;
