import { Col } from "@ui/wrapper";
import styled, { tablet, phone, themeGet } from "@styled";

export const SectionWrap = styled.div`
  position: relative;
  min-height: 707px;
  overflow: hidden;
  width: 100%;
`;

export const StyledBackground = styled.div`
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  width: 100%;
  max-width: 100vw;
  height: 707px;
  border-bottom-left-radius: 180px;
  border-top-right-radius: 180px;
  padding-block: 150px;

  @media (${tablet}) {
    align-items: flex-end;
    padding-bottom: 70px;
  }

  @media (${phone}) {
    border-bottom-left-radius: 47px;
    border-top-right-radius: 47px;
  }
`;

export const StyledCol = styled(Col)`
  margin-right: -100px;
  display: flex;
  flex-direction: column;

  @media (${tablet}) {
    margin-right: 0;
    justify-content: flex-end !important;
  }
`;

export const StyledTitle = styled.h2`
  font-weight: 600;
  color: #333333;
  line-height: 1.34;
  margin-block-end: 100px;

  @media (${tablet}) {
    margin-block-end: 30px;
    color: #ffffff;
  }

  span {
    color: ${({ $color }) => themeGet(`colors.${$color}`)};
  }
`;

export const StyledButton = styled.div`
  padding-left: 30px;

  @media (${tablet}) {
    padding-left: 0;
  }
`;
