import React, { useMemo } from "react";
import PropTypes from "prop-types";
// components
import Seo from "@components/seo";
// containers
import LegalPageContainer from "@containers/legal-page-container";
import Header from "@containers/header";
// layouts
import MainLayout from "@layout/main-layout";
// hooks
import {
  useTermsAndConditionsData,
  useTermsAndConditionsHeaderData,
} from "@hooks";

const TermsAndConditionsPage = ({ location }) => {
  // hooks
  const termsAndConditionsData = useTermsAndConditionsData();
  const termsAndConditionsHeaderData = useTermsAndConditionsHeaderData();
  // memo
  const termsAndConditionsFormattedData = useMemo(
    () => ({
      title: "Terms and Conditions",
      content: termsAndConditionsData,
    }),
    [termsAndConditionsData]
  );

  return (
    <MainLayout location={location}>
      <Seo title="Terms and Conditions" />
      <Header
        data={termsAndConditionsHeaderData}
        isDefaultSticky
        hoverColor="#3DC7FA"
      />
      <main className="site-wrapper-reveal">
        <LegalPageContainer data={termsAndConditionsFormattedData} />
      </main>
    </MainLayout>
  );
};

TermsAndConditionsPage.propTypes = {
  location: PropTypes.shape({}),
};

export default TermsAndConditionsPage;
