import image1 from "@data/images/box-image/image-03-480x298.jpg";
import image2 from "@data/images/box-image/image-06-480x298.jpg";
import image3 from "@data/images/box-image/image-05-480x298.jpg";

export const data = [
  {
    id: 1,
    image: { src: image1 },
    title: "Info Security Management",
    description:
      "At Mitech, we have a holistic and integrated approach towards core modernization to experience technological evolution.",
    path: "/",
  },
  {
    id: 2,
    image: { src: image2 },
    title: "Highly Professional Staffs",
    description:
      "Having obtained the official & formal training in IT technology and technical fields, our staffs know best.",
    path: "/",
  },
  {
    id: 3,
    image: { src: image3 },
    title: "Info Security Management",
    description:
      "At Mitech, we have a holistic and integrated approach towards core modernization to experience technological evolution.",
    path: "/",
  },
];
