import * as yup from "yup";
// constants
import { phoneRegex } from "@constants/form.constants";

export const clientContactUsSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name cannot be empty")
    .min(2, `Name should be longer than 2 characters`)
    .max(20, `Name should be shorter than 20 characters`),
  email: yup.string().required("Email cannot be empty").email("Invalid email"),
  phone: yup
    .string()
    .required("Phone number cannot be empty")
    .min(11, `Phone number should be longer than 10 characters`)
    .max(12, `Phone number should be shorter than 13 characters`)
    .matches(phoneRegex, "Invalid phone number"),
  message: yup
    .string()
    .required("Message cannot be empty")
    .min(10, `Message should be longer than 10 characters`)
    .max(200, `Message should be shorter than 200 characters`),
  service: yup.object().shape({
    value: yup.string(),
    label: yup.string().required("Service cannot be empty"),
  }),
  industry: yup.object().shape({
    value: yup.string(),
    label: yup.string().required("Industry cannot be empty"),
  }),
});
