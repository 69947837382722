import image1 from "@data/images/icons/icon-18-120x120.png";
import image2 from "@data/images/icons/icon-19-120x120.png";
import image3 from "@data/images/icons/icon-20-120x120.png";

export const data = [
  {
    id: 1,
    image: { src: image1 },
    title: "How can we help your business?",
    description:
      "We provide the most responsive and functional IT design for companies and businesses worldwide.",
  },
  {
    id: 2,
    image: { src: image2 },
    title: "What are the advantages of Mitech?",
    description:
      "It’s possible to simultaneously manage and transform information from one server to another.",
  },
  {
    id: 3,
    image: { src: image3 },
    title: "How working process is simplified?",
    description:
      "Back up your database, store in a safe and secure place while still maintaining its accessibility.",
  },
];
