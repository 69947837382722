import image1 from "@data/images/icons/icon-13-80x83.png";
import image2 from "@data/images/icons/icon-14-80x83.png";
import image3 from "@data/images/icons/icon-15-80x83.png";
import image4 from "@data/images/icons/icon-16-80x83.png";

export const data = [
  {
    id: 1,
    image: { src: image1 },
    title: "Business Reform",
    description:
      "We propose feasible & practical plans for successfully transform businesses based on their needs.",
  },
  {
    id: 2,
    image: { src: image2 },
    title: "Infrastructure Plan",
    description:
      "Mitech takes into account all conditions and budgets needed for building infrastructure plan.",
  },
  {
    id: 3,
    image: { src: image3 },
    title: "Firewall Advancement",
    description:
      "Enhancing the strength and security of firewalls to protect online data from malicious sources.",
  },
  {
    id: 4,
    image: { src: image4 },
    title: "Desktop Computing",
    description:
      "Programming is taken care of by our experienced and professional specialist in IT management.",
  },
];
