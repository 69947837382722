import React from "react";
import PropTypes from "prop-types";
// templates
import CandidateJobsTemplate from "src/templates/candidate-jobs";

const CandidateJobsPage = ({ location }) => {
  return <CandidateJobsTemplate location={location} />;
};

CandidateJobsPage.propTypes = {
  location: PropTypes.shape({}),
};

export default CandidateJobsPage;
