import React, { useMemo } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// components
import Seo from "@components/seo";
import PageHeaderWithImage from "@components/page-header-with-image";
import RecentPosts from "@components/recent-posts";
import PopularTags from "@components/popular-tags";
import Pagination from "@components/pagination";
// containers
import Header from "@containers/header";
import Posts from "@containers/posts";
// ui
import { Container, Row, Col } from "@ui/wrapper";
// layouts
import MainLayout from "@layout/main-layout";
// hooks
import { useBlogHeaderData, useTagsData } from "@hooks";
// types
import { PostType } from "@utils/types";
// styles
import { StyledSection, StyledSidebar } from "./style";

const BlogPage = ({ location, pageContext, data }) => {
  // hooks
  const blogHeaderData = useBlogHeaderData();
  const tagsData = useTagsData();
  // memo
  const postsData = useMemo(
    () => data?.allStrapiPost?.edges,
    [data?.allStrapiPost?.edges]
  );

  const recentPostsData = useMemo(
    () => ({
      title: "Recent Posts",
      posts: pageContext.recentPosts,
    }),
    [pageContext.recentPosts]
  );

  const popularTagsData = useMemo(() => {
    let popularTags = [];

    if (tagsData.length) {
      tagsData.forEach((tag) => {
        popularTags.push(tag.node.tag);
      });
    }

    return {
      title: "Popular Tags",
      tags: popularTags,
    };
  }, [tagsData]);

  return (
    <MainLayout location={location}>
      <Seo title="Stream Talent - Blog" />
      <Header data={blogHeaderData} buttonColor="#1FB591" />
      <main className="site-wrapper-reveal">
        <PageHeaderWithImage
          pageContext={pageContext}
          location={location}
          title="Blog"
        />
        <StyledSection>
          <Container>
            <Row>
              <Col lg={{ span: 4, order: 1 }} xs={{ span: 12, order: 2 }}>
                <StyledSidebar>
                  {!!popularTagsData.tags.length && (
                    <PopularTags data={popularTagsData} />
                  )}
                  {/* TODO: uncomment it later */}
                  {/* <AdWidget data={content["ad-section"]} mb="47px" /> */}
                  {!!recentPostsData.posts.length && (
                    <RecentPosts mb="50px" data={recentPostsData} />
                  )}
                </StyledSidebar>
              </Col>
              <Col lg={{ span: 8, order: 2 }} xs={{ span: 12, order: 1 }}>
                <Posts data={{ posts: postsData }} />
                {!!postsData.length && pageContext.numberOfPages > 1 && (
                  <Pagination
                    mt="40px"
                    rootPage="/blog"
                    currentPage={pageContext.currentPage}
                    numberOfPages={pageContext.numberOfPages}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </StyledSection>
      </main>
    </MainLayout>
  );
};

export const query = graphql`
  query BlogPostsDataQuery($skip: Int, $limit: Int) {
    allStrapiPost(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          id
          title
          description
          image {
            url
          }
          content {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          date
          author {
            name
            description
            linkedin_link
            avatar {
              url
            }
          }
          tags {
            tag
          }
        }
      }
    }
  }
`;

BlogPage.propTypes = {
  pageContext: PropTypes.shape({
    limit: PropTypes.number,
    currentPage: PropTypes.number,
    numberOfPages: PropTypes.number,
    recentPosts: PropTypes.arrayOf(PropTypes.string),
  }),
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allStrapiPost: PropTypes.shape({ edges: PropTypes.arrayOf(PostType) }),
  }),
};

export default BlogPage;
