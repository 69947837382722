import React, { useMemo } from "react";
import PropTypes from "prop-types";
// components
import Seo from "@components/seo";
import IntroBlock from "@components/intro-block";
// containers
import Header from "@containers/header";
import JobsWidget from "@containers/jobs-widget";
import BlockWrapper from "@containers/block-wrapper";
// layouts
import MainLayout from "@layout/main-layout";
// hooks
import { useCandidateJobsIntroData, useCandidateJobsHeaderData } from "@hooks";
// utils
import { createStrapiContentUrl } from "@utils/content.utils";

const CandidateJobsTemplate = ({ location }) => {
  // hooks
  const candidateJobsHeaderData = useCandidateJobsHeaderData();
  const candidateJobsIntroData = useCandidateJobsIntroData();
  // memo
  const introBlockData = useMemo(
    () => ({
      ...candidateJobsIntroData,
      image: createStrapiContentUrl(candidateJobsIntroData.image.url),
    }),
    [candidateJobsIntroData]
  );

  return (
    <MainLayout location={location}>
      <Seo
        title="Jobs in Australia - Recruitment Agency - Stream Talent"
        description="Search for a new job with Stream Talent. We represent Australia's best companies, finding you the right job for your next career move. "
      />
      <Header data={candidateJobsHeaderData} />
      <main className="site-wrapper-reveal">
        <IntroBlock
          data={introBlockData}
          gradientType="candidate"
          bgHeight="462px"
          isRounded={false}
        />
        <BlockWrapper onlyBottom>
          <JobsWidget />
        </BlockWrapper>
      </main>
    </MainLayout>
  );
};

CandidateJobsTemplate.propTypes = {
  location: PropTypes.shape({}),
};

export default CandidateJobsTemplate;
