import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query CandidateIndustriesBlockDataQuery {
    allStrapiCandidateIndustriesBlock {
      edges {
        node {
          title
          subtitle
          industries {
            id
            title
            description
            icon_name
          }
          first_button_text
          first_button_link
          second_button_text
          second_button_link
        }
      }
    }
  }
`;

const useCandidateIndustriesBlockData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiCandidateIndustriesBlock?.edges[0]?.node;
};

export default useCandidateIndustriesBlockData;
