import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query TagsDataQuery {
    allStrapiTag {
      edges {
        node {
          tag
        }
      }
    }
  }
`;

const useTagsData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiTag?.edges;
};

export default useTagsData;
