import styled, { themeGet, phone } from "@styled";

export const SectionWrap = styled.section`
  max-width: 789px;
  width: 100%;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 40px;
`;

export const StyledBox = styled.div`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 130%;
    left: 50%;
    bottom: 0;
    width: 4px;
    height: 150px;
    transform: translateY(-50%) rotate(90deg);
    background-color: ${themeGet("colors.secondary")};
  }
`;

export const StyledSubtitle = styled.h4`
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.03em;
  margin-top: 124px;

  color: #333333;

  @media (${phone}) {
    margin-top: 84px;
  }
`;

export const StyledContent = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  margin-top: 18px;

  color: #696969;
`;
