import React from "react";
import PropTypes from "prop-types";
// styles
import { SectionWrap } from "./style";

const BlockWrapper = ({ children, onlyTop, onlyBottom }) => {
  return (
    <SectionWrap onlyTop={onlyTop} onlyBottom={onlyBottom}>
      {children}
    </SectionWrap>
  );
};

BlockWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onlyTop: PropTypes.bool,
  onlyBottom: PropTypes.bool,
};

export default BlockWrapper;
