import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query ServicesIntroDataQuery {
    allStrapiServicesIntro {
      edges {
        node {
          title
          subtitle
          image {
            url
          }
        }
      }
    }
  }
`;

const useServicesIntroData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiServicesIntro?.edges[0]?.node;
};

export default useServicesIntroData;
