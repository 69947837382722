import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query HowWeWorkDataQuery {
    allStrapiHowWeWork {
      edges {
        node {
          subtitle
          title
          steps {
            id
            title
            description
            link
          }
          button_text
        }
      }
    }
  }
`;

const useHowWeWorkData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiHowWeWork?.edges[0]?.node;
};

export default useHowWeWorkData;
