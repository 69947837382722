import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
// components
import PageHeaderWithImage from "@components/page-header-with-image";
import AuthorDetails from "@components/author-details";
import NavigationArea from "@components/navigation";
import AuthorMeta from "@components/author-meta";
import BlogMeta from "@components/blog-meta";
import Tags from "@components/tags";
import BlogMedia from "@components/blog/blog-media";
import SocialShare from "@components/social-share";
import Seo from "@components/seo";
// ui
import { Container } from "@ui/wrapper";
// containers
import Header from "@containers/header";
// layouts
import MainLayout from "@layout/main-layout";
// hooks
import { usePostHeaderData } from "@hooks";
// utils
import { createStrapiContentUrl } from "@utils/content.utils";
import { slugify, isBrowser } from "@utils";
// types
import { ImagePropType, PostType } from "@utils/types";
// style
import {
  StyledSection,
  StyledBlogWrap,
  StyledTitle,
  StyledHeaderMeta,
  StyledContent,
  StyledFooter,
} from "./style";

const SingleBlogTemplate = ({ pageContext, location }) => {
  // hooks
  const postHeaderData = usePostHeaderData();
  // memo
  const tags = useMemo(
    () => pageContext.post.tags.map(({ tag }) => tag),
    [pageContext.post.tags]
  );

  const authorAvatar = useMemo(
    () => createStrapiContentUrl(pageContext?.post?.author?.avatar?.url),
    [pageContext?.post?.author?.avatar?.url]
  );

  const socials = useMemo(
    () => [
      {
        id: 1,
        icon: "fab fa-linkedin",
        title: "linkedin",
        link: pageContext?.post?.author?.linkedin_link,
      },
    ],
    [pageContext?.post?.author?.linkedin_link]
  );

  const navigationData = useMemo(
    () => ({
      previous: pageContext.previous
        ? {
            ...pageContext.previous,
            image: {
              src: createStrapiContentUrl(pageContext.previous.image.url),
            },
          }
        : null,
      next: pageContext.next
        ? {
            ...pageContext.next,
            image: { src: createStrapiContentUrl(pageContext.next.image.url) },
          }
        : null,
    }),
    [pageContext.previous, pageContext.next]
  );

  return (
    <MainLayout location={location}>
      <Seo
        title={`Stream Talent - ${pageContext.post.title}`}
        description={pageContext.post.description}
        image={createStrapiContentUrl(pageContext.post.image.url)}
      />
      <Header data={postHeaderData} buttonColor="#1FB591" />
      <main className="site-wrapper-reveal">
        <PageHeaderWithImage
          pageContext={pageContext}
          location={location}
          title="Blog"
        />
        <StyledSection>
          <Container>
            <BlogMedia
              format="image"
              image={{
                src: createStrapiContentUrl(pageContext.post.image.url),
              }}
              title={pageContext.post.title}
              mb="46px"
            />
            <StyledBlogWrap>
              <div className="blog-header">
                <StyledTitle>{pageContext.post.title}</StyledTitle>
                <StyledHeaderMeta>
                  {pageContext?.post?.author && (
                    <AuthorMeta
                      mt="10px"
                      mr="20px"
                      slug={slugify(pageContext?.post?.author?.name)}
                      name={pageContext?.post?.author?.name}
                      image={authorAvatar}
                    />
                  )}
                  <BlogMeta
                    mt="10px"
                    mr="20px"
                    text={format(
                      new Date(pageContext.post.date),
                      "MMMM d, yyyy"
                    )}
                    icon="fa fa-calendar-alt"
                  />
                </StyledHeaderMeta>
              </div>
              <StyledContent
                dangerouslySetInnerHTML={{
                  __html:
                    pageContext.post.content.data.childMarkdownRemark.html,
                }}
              />
              <StyledFooter>
                <Tags tags={tags} mb={["10px"]} />
                <SocialShare
                  url={isBrowser ? window.location.href : ""}
                  title={pageContext.post.title}
                />
              </StyledFooter>
              {pageContext?.post?.author && (
                <AuthorDetails
                  avatar={authorAvatar}
                  name={pageContext.post.author.name}
                  description={pageContext.post.author.description}
                  socials={socials}
                />
              )}
              <NavigationArea data={navigationData} />
            </StyledBlogWrap>
          </Container>
        </StyledSection>
      </main>
    </MainLayout>
  );
};

SingleBlogTemplate.propTypes = {
  location: PropTypes.shape({}),
  pageContext: PropTypes.shape({
    post: PostType,
    next: PropTypes.shape({
      image: ImagePropType,
    }),
    previous: PropTypes.shape({
      image: ImagePropType,
    }),
  }),
};

export default SingleBlogTemplate;
