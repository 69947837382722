const footerData = {
  contact: {
    phone: "190068668",
    address: "58 Howard Street #2 San Francisco, CA 941",
    email: "hello@mitech.com",
    website: "https://example.com/",
  },
  copyright:
    "Mitech. <a href='https://example.com/' target='_blank' rel='noopener noreferrer'>All Rights Reserved.</a>",
  socials: [
    {
      id: 1,
      icon: "fab fa-facebook-f",
      link: "https://www.facebook.com",
      title: "Facebook",
    },
    {
      id: 2,
      icon: "fab fa-twitter",
      link: "https://www.twitter.com",
      title: "Twitter",
    },
    {
      id: 3,
      icon: "fab fa-instagram",
      link: "https://www.instagram.com",
      title: "Instagram",
    },
    {
      id: 4,
      icon: "fab fa-linkedin",
      link: "https://www.linkedin.com",
      title: "Linkedin",
    },
  ],
};

export default footerData;
