import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query AboutUsMainTextDataQuery {
    allStrapiAboutUsMainText {
      edges {
        node {
          title
          subtitle
          content {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`;

const useAboutUsMainTextData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiAboutUsMainText?.edges[0]?.node;
};

export default useAboutUsMainTextData;
