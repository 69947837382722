const headerData = {
  slogan:
    "<span>Now Hiring:</span> Are you a driven and motivated 1st Line IT Support Engineer?",
  menu: [
    {
      id: "menu-1",
      text: "Home",
      link: "/",
      submenu: [
        {
          id: "menu-11",
          text: "Infotechno",
          link: "/",
        },
        {
          id: "menu-12",
          text: "Appointment",
          link: "/homepages/appointment",
        },
        {
          id: "menu-13",
          text: "Processing",
          link: "/homepages/processing",
        },
        {
          id: "menu-14",
          text: "Services",
          link: "/homepages/services",
        },
        {
          id: "menu-15",
          text: "Resolutions",
          link: "/homepages/resolutions",
        },
        {
          id: "menu-16",
          text: "Cybersecurity",
          link: "//homepages/cybersecurity",
        },
      ],
    },
    {
      id: "menu-2",
      text: "Company",
      link: "/about-us",
      submenu: [
        {
          id: "menu-21",
          text: "About Us",
          link: "/about-us",
        },
        {
          id: "menu-22",
          text: "Contact Us",
          link: "/contact-us",
        },
        {
          id: "menu-23",
          text: "Leadership",
          link: "/leadership",
        },
        {
          id: "menu-24",
          text: "Why Choose Us",
          link: "/why-choose-us",
        },
        {
          id: "menu-25",
          text: "Our History",
          link: "/our-history",
        },
        {
          id: "menu-26",
          text: "FAQs",
          link: "/faq",
        },
        {
          id: "menu-27",
          text: "Careers",
          link: "/careers",
        },
        {
          id: "menu-28",
          text: "Pricing Plans",
          link: "/pricing-plan",
        },
      ],
    },
    {
      id: "menu-3",
      text: "Services",
      link: "/it-services",
      submenu: [
        {
          id: "menu-31",
          text: "IT Services",
          link: "/it-services",
        },
        {
          id: "menu-32",
          text: "IT Solutions",
          link: "/it-solutions",
        },
      ],
    },
    {
      id: "menu-4",
      text: "Elements",
      link: "/",
      megamenu: [
        {
          title: "Element Group 01",
          submenu: [
            {
              id: "menu-411",
              text: "Accordion",
              link: "/elements/accordion",
            },
            {
              id: "menu-412",
              text: "Box Icon",
              link: "/elements/box-icon",
            },
            {
              id: "menu-413",
              text: "Box Image",
              link: "/elements/box-image",
            },
            {
              id: "menu-414",
              text: "Box Large Image",
              link: "/elements/box-large-image",
            },
            {
              id: "menu-415",
              text: "Buttons",
              link: "/elements/button",
            },
            {
              id: "menu-416",
              text: "Call to action",
              link: "/elements/call-to-action",
            },
            {
              id: "menu-417",
              text: "Client Logo",
              link: "/elements/client-logo",
            },
          ],
        },
        {
          title: "Element Group 02",
          submenu: [
            {
              id: "menu-421",
              text: "Counters",
              link: "/elements/counters",
            },
            {
              id: "menu-422",
              text: "Dividers",
              link: "/elements/dividers",
            },
            {
              id: "menu-423",
              text: "Flexible image slider",
              link: "/elements/flexible-image-slider",
            },
            {
              id: "menu-424",
              text: "Google Map",
              link: "/elements/google-map",
            },
            {
              id: "menu-425",
              text: "Gradation",
              link: "/elements/gradation",
            },
            {
              id: "menu-426",
              text: "Lists",
              link: "/elements/lists",
            },
            {
              id: "menu-427",
              text: "Message box",
              link: "/elements/message-box",
            },
          ],
        },
        {
          title: "Element Group 03",
          submenu: [
            {
              id: "menu-431",
              text: "Popup Video",
              link: "/elements/popup-video",
            },
            {
              id: "menu-432",
              text: "Pricing Box",
              link: "/elements/pricing-box",
            },
            {
              id: "menu-433",
              text: "Progress Bar",
              link: "/elements/progress-bar",
            },
            {
              id: "menu-434",
              text: "Progress Circle",
              link: "/elements/progress-circle",
            },
            {
              id: "menu-435",
              text: "Social Networks",
              link: "/elements/social-networks",
            },
            {
              id: "menu-436",
              text: "Tabs",
              link: "/elements/tabs",
            },
            {
              id: "menu-437",
              text: "Team member",
              link: "/elements/team-member",
            },
          ],
        },
        {
          title: "Element Group 04",
          submenu: [
            {
              id: "menu-441",
              text: "Testimonials",
              link: "/elements/testimonials",
            },
            {
              id: "menu-442",
              text: "Timeline",
              link: "/elements/timeline",
            },
            {
              id: "menu-443",
              text: "Typed Text",
              link: "/elements/typed-text",
            },
          ],
        },
      ],
    },
    {
      id: "menu-5",
      text: "Case Studies",
      link: "/case-studies",
    },
    {
      id: "menu-6",
      text: "Blog",
      link: "/blog",
      submenu: [
        {
          id: "menu-61",
          text: "Single Blog",
          link: "/5-ways-technology-has-improved-business-today",
        },
      ],
    },
  ],
};

export default headerData;
