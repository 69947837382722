import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query ClientIndustryHeaderDataQuery {
    allStrapiClientIndustryHeader {
      edges {
        node {
          left_links {
            text
            url
          }
          right_links {
            text
            url
          }
        }
      }
    }
  }
`;

const useClientIndustryHeaderData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiClientIndustryHeader?.edges[0]?.node;
};

export default useClientIndustryHeaderData;
