// styles
import styled, { laptop, tablet, phone, themeGet } from "@styled";

export const SectionWrap = styled.section`
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  display: flex;
`;

export const StyledFirstBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background-image: linear-gradient(
      180deg,
      #007ded 0%,
      rgba(0, 125, 237, 0.29) 21.33%,
      rgba(0, 125, 237, 0.13) 26.82%,
      rgba(249, 243, 241, 0) 37.54%
    ),
    url(${({ url }) => url});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  width: 50vw;
  max-width: 100vw;
  height: 100vh;
  border-top-right-radius: none;
  transition: 0.3s all ease-in-out;

  &:hover {
    border-top-right-radius: 100px;
    z-index: 2;
    width: 90vw;

    > #text {
      padding-left: 170px;
    }
  }

  @media (${tablet}) {
    width: 100vw;
    height: 50vh;

    &:hover {
      border-top-right-radius: 0;
      border-bottom-right-radius: 50px;
      z-index: 2;
      width: 100vw;
      height: 90vh;

      > #text {
        padding-left: 25px;
      }
    }
  }
`;

export const StyledSecondBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-image: linear-gradient(
      180deg,
      #1fb591 0%,
      rgba(31, 181, 145, 0.29) 21.33%,
      rgba(31, 181, 145, 0.13) 26.82%,
      rgba(249, 243, 241, 0) 37.54%
    ),
    url(${({ url }) => url});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  width: 50vw;
  max-width: 100vw;
  height: 100vh;
  border-top-left-radius: none;
  transition: 0.3s all ease-in-out;

  &:hover {
    border-top-left-radius: 100px;
    z-index: 2;
    width: 90vw;

    > #text {
      padding-left: 170px;
    }
  }

  @media (${tablet}) {
    width: 100vw;
    height: 50vh;
    top: auto;
    bottom: 0;

    &:hover {
      border-top-left-radius: 50px;
      z-index: 2;
      width: 100vw;
      height: 90vh;

      > #text {
        padding-left: 25px;
      }
    }
  }
`;

export const StyledTextWrapper = styled.div`
  width: 100%;
  padding-left: 105px;
  display: grid;
  align-content: end;
  margin-bottom: 105px;
  transition: 0.3s all ease-in-out;

  @media (${laptop}) {
    padding-left: 50px;
    margin-bottom: 50px;
  }

  @media (${phone}) {
    padding-left: 25px;
    margin-bottom: 64px;
  }
`;

export const StyledSubtitle = styled.h5`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;

  color: #ffffff;
`;

export const StyledLeftTitle = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 70px;
  color: ${themeGet("colors.primary")};
  margin-top: 12px;

  > span {
    color: #fff;
    background: linear-gradient(
      89.93deg,
      #007deb 0%,
      rgba(0, 125, 235, 0) 86.13%
    );
    border-radius: 10px;
    padding: 10px;
  }

  @media (${laptop}) {
    font-size: 60px;
  }

  @media (${tablet}) {
    font-size: 50px;
  }

  @media (${phone}) {
    font-weight: 400;
    font-size: 35px;
    line-height: 120%;

    > span {
      padding: 5px;
    }
  }
`;

export const StyledRightTitle = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 70px;
  color: ${themeGet("colors.candidate")};
  margin-top: 12px;

  > span {
    color: #fff;
    background: linear-gradient(
      89.93deg,
      #1fb591 0%,
      rgba(31, 181, 145, 0) 86.13%
    );
    border-radius: 10px;
    padding: 10px;
  }

  @media (${laptop}) {
    font-size: 60px;
  }

  @media (${tablet}) {
    font-size: 50px;
  }

  @media (${phone}) {
    font-weight: 400;
    font-size: 35px;
    line-height: 120%;

    > span {
      padding: 5px;
    }
  }
`;
