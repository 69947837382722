import React from "react";
import PropTypes from "prop-types";
// ui
import { Container, Row, Col } from "@ui/wrapper";
// utils
import { createStrapiContentUrl } from "@utils/content.utils";
// types
import { ImagePropType } from "@utils/types";
// styles
import {
  StyledSection,
  StyledTitle,
  StyledDescription,
  StyledImage,
  ListWrapper,
  StyledList,
  StyledItem,
  StyledItemText,
  StyledLink,
} from "./style";

const IndustryDescription = ({ data }) => {
  return (
    <StyledSection>
      <Container>
        <Row textAlign={[null, null, null, "left"]}>
          <Col xl={6} lg={6}>
            <StyledTitle>{data.first_title}</StyledTitle>
            <StyledDescription
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
            <ListWrapper>
              <StyledTitle>{data.second_title}</StyledTitle>
              <StyledList>
                {data.roles.map((role) => (
                  <StyledItem key={role.id}>
                    <span className="icon">
                      <i className="fa fa-check"></i>
                    </span>
                    <StyledItemText>{role.text}</StyledItemText>
                  </StyledItem>
                ))}
              </StyledList>
            </ListWrapper>
            <StyledLink
              mt={["42px", null, "56px"]}
              text={null}
              linkText={data?.link_text}
              path={data.link_url}
            />
          </Col>
          <Col xl={6} lg={6}>
            <StyledImage
              src={createStrapiContentUrl(data.image.url)}
              alt={data.first_title}
            />
          </Col>
        </Row>
      </Container>
    </StyledSection>
  );
};

IndustryDescription.propTypes = {
  data: PropTypes.shape({
    first_title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    second_title: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
    image: ImagePropType.isRequired,
    link_text: PropTypes.string.isRequired,
    link_url: PropTypes.string.isRequired,
  }),
};

export default IndustryDescription;
