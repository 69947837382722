import { createElementTypeByLocation } from "@utils/content.utils";

export const customStyles = (isError) => ({
  dropdownIndicator: (provided) => ({
    ...provided,
    color: createElementTypeByLocation(
      "#1FB591 !important",
      "#007DED !important"
    ),
    transition: "all 0.3s",
    display: "flex",
    padding: "0 !important",
    paddingLeft: "5px !important",
  }),
  placeholder: (provided) => ({
    ...provided,
    padding: "3px 0",
    color: "#696969",
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #E8E8E8",
    padding: "8px 22px",
    color: "#333333 !important",
    backgroundColor: state.isFocused ? "#F8F8F8" : "#fff",
    "&:active": {
      backgroundColor: state.isFocused ? "#B2D4FF" : null,
    },
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? createElementTypeByLocation("#C7F2E5", "#DEF5FF")
      : "#f5f5f5",
    transition: "outline 0.3s",
    boxShadow: "none",
    borderRadius: "4px",
    padding: "3px 20px",
    paddingLeft: "12px",
    height: "56px",
    fontSize: "16px",
    border: isError
      ? "1px solid #dc3545 !important"
      : state.isFocused
      ? `1px solid ${createElementTypeByLocation(
          "#1FB591 !important",
          "#007DED !important"
        )}`
      : "none",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    borderRadius: "4px",
    border: "none",
    boxSizing: "border-box",
    boxShadow: "none",
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: "none",
    filter: "drop-shadow(0px 2px 17px #E6E6E6)",
  }),
  input: (provided) => ({
    ...provided,
    color: createElementTypeByLocation("#1FB591", "#007DED"),
  }),
});
