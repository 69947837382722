import React from "react";
import PropTypes from "prop-types";
// ui
import { Container, Row } from "@ui/wrapper";
// components
import VideoButton from "@components/video-button";
// hooks
import { useWindowSize } from "@hooks";
// utils
import {
  createColorByLocation,
  createStrapiContentUrl,
} from "@utils/content.utils";
// types
import { ImagePropType } from "@utils/types";
// styles
import {
  SectionWrap,
  StyledTitle,
  StyledButton,
  StyledBackground,
  StyledCol,
} from "./style";

const VideoIntro = ({ data, buttonColor }) => {
  // hooks
  const { isTablet, isMobile } = useWindowSize();

  return (
    <SectionWrap>
      <StyledBackground url={createStrapiContentUrl(data.image.url)}>
        <Container>
          <Row>
            <StyledCol lg={6} md={10} xs={11} ml="auto">
              <StyledTitle
                $color={createColorByLocation()}
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              />
              <StyledButton>
                <VideoButton
                  color={createColorByLocation()}
                  textStyle={2}
                  textColor={isTablet || isMobile ? "#ffffff" : buttonColor}
                  content={data.button_text}
                  link={data.video_url}
                />
              </StyledButton>
            </StyledCol>
          </Row>
        </Container>
      </StyledBackground>
    </SectionWrap>
  );
};

VideoIntro.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    video_url: PropTypes.string.isRequired,
    button_text: PropTypes.string.isRequired,
    image: ImagePropType.isRequired,
  }),
  buttonColor: PropTypes.string,
};

VideoIntro.defaultProps = {
  buttonColor: "#FFFFFF",
};

export default VideoIntro;
