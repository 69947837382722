import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
// utils
import { createColorWithThemeByLocation } from "@utils/content.utils";
// styles
import styled, { css, themeGet, laptop, phone } from "@styled";
// animations
import { fadeInDown } from "@assets/css/animations";

export const HeaderRoot = styled.header`
  position: absolute;
  left: 0;
  right: 0;
  width: ${({ $width }) => $width};
  z-index: 1001;

  ${({ isSticky, isBurgerOpen }) =>
    isSticky &&
    css`
      position: fixed;
      background-color: ${isBurgerOpen ? "transparent" : "#ffffff"};
      box-shadow: ${isBurgerOpen ? "none " : "0px 2px 17px #e6e6e6"};
      animation: 0.95s ease-in-out 0s normal none 1 running ${fadeInDown};

      ${StyledLink} {
        color: ${themeGet("colors.heading")} !important;

        &::after {
          background: ${themeGet("colors.heading")};
        }
      }
    `}
`;

export const HeaderNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1350px) {
    max-width: 1170px;
  }

  @media (${laptop}) {
    max-width: 90%;
  }
`;

export const HeaderLogoNav = styled.nav`
  padding: 20px 0;
  max-width: 211px;
  width: 100%;
  margin: 0 auto;
`;

export const HeaderLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderLogo = styled.img`
  cursor: pointer;
  margin-right: 42px;
`;

export const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (${laptop}) {
    display: none;
  }
`;

export const RightBlock = styled.div`
  display: block;

  @media (${laptop}) {
    display: none;
  }
`;

export const StyledLink = styled(AnchorLink)`
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: ${({ isSticky }) =>
    isSticky ? `#333333 !important` : "#ffffff !important"};
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  text-decoration: initial !important;
  margin-right: ${({ isLast }) => (isLast ? "0" : "44px")};

  &::after {
    content: "";
    width: 0;
    height: 2px;
    display: block;
    background: ${({ isSticky }) => (isSticky ? `#333333` : "#ffffff")};
    transition: 0.3s;
  }

  &:hover::after {
    width: 100%;
  }
`;

export const StyledButton = styled(Link)`
  text-decoration: initial !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff !important;
  cursor: pointer;
  transition: all 0.3s ease;
  background: ${({ $color }) => $color};
  border-radius: 4px;
  padding: 10px 26px;

  &:hover {
    background: ${({ $hover_color }) => $hover_color};
  }
`;

export const BurgerButton = styled.div`
  position: relative;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0;
  width: 26px;
  height: 16px;
  z-index: 101;
  cursor: pointer;

  @media (${laptop}) {
    display: flex;
  }

  @media (${phone}) {
    margin-left: auto;
  }

  > span {
    position: absolute;
    background-color: ${({ isSticky }) =>
      isSticky ? createColorWithThemeByLocation() : "#fff"};
    margin: auto;
    height: 2px;
    transition: all 0.49s cubic-bezier(0.4, 0, 0.2, 1.9);

    &:nth-child(1) {
      top: 0;
      width: 26px;
    }

    &:nth-child(2) {
      top: 7px;
      width: 26px;
    }

    &:nth-child(3) {
      top: 14px;
      right: 0;
      width: 15px;
    }
  }

  &:hover > span:last-child {
    width: 100%;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      > span {
        &:nth-child(1) {
          transform: rotate(45deg);
          width: 26px !important;
          top: calc(50% - (2px)) !important;
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          width: 26px !important;
          top: calc(50% - (2px)) !important;
        }
      }
    `}
`;
