import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query AboutUsHeaderDataQuery {
    allStrapiAboutUsHeader {
      edges {
        node {
          left_links {
            text
            url
          }
          right_links {
            text
            url
          }
        }
      }
    }
  }
`;

const useAboutUsHeaderData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiAboutUsHeader?.edges[0]?.node;
};

export default useAboutUsHeaderData;
