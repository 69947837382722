import React from "react";
import PropTypes from "prop-types";
// ui
import { Container, Row, Col } from "@ui/wrapper";
import Gradation from "@ui/gradation";
// utils
import { createContactUsLinkByLocation } from "@utils/content.utils";
// styles
import {
  SectitonWrap,
  GradationRow,
  SectionTitleWrap,
  StepWrap,
  StyledTitle,
  StyledSubtitle,
} from "./style";

const HowWeWork = ({ data }) => {
  return (
    <SectitonWrap>
      <Container>
        <Row>
          <Col md={6}>
            <SectionTitleWrap>
              <StyledSubtitle as="h6">{data.subtitle}</StyledSubtitle>
              <StyledTitle
                as="h4"
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              />
            </SectionTitleWrap>
          </Col>
          <Col md={6}>
            {data?.steps && (
              <StepWrap>
                <h3>
                  <mark>{`0${data.steps.length}`}</mark>Steps
                </h3>
              </StepWrap>
            )}
          </Col>
        </Row>
        <GradationRow>
          {data.steps?.map((step, i) => (
            <Gradation
              key={step.id}
              number={i + 1}
              isLast={i === data.steps.length - 1}
              title={step.title}
              description={step.description}
              path={createContactUsLinkByLocation(step.link)}
              button={data.button_text}
            />
          ))}
        </GradationRow>
      </Container>
    </SectitonWrap>
  );
};

HowWeWork.propTypes = {
  data: PropTypes.shape({
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      })
    ),
    button_text: PropTypes.string.isRequired,
  }),
};

export default HowWeWork;
