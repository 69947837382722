import React from "react";
import PropTypes from "prop-types";
// components
import SectionTitle from "@components/section-title";
// ui
import { Container } from "@ui/wrapper";
// styles
import { SectionWrap, StyledBox, StyledSubtitle, StyledContent } from "./style";

const IndustryTextIntro = ({ data }) => {
  return (
    <Container>
      <SectionWrap>
        <StyledBox>
          <SectionTitle
            mb={["30px", null, "40px"]}
            subtitle=""
            title={data?.title}
          />
        </StyledBox>
        <StyledSubtitle>{data?.subtitle}</StyledSubtitle>
        <StyledContent>{data?.content}</StyledContent>
      </SectionWrap>
    </Container>
  );
};

IndustryTextIntro.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }),
};

export default IndustryTextIntro;
