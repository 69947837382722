import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
// containers
import Burger from "@containers/burger";
// hooks
import { useLogosData, useSticky, useWindowSize } from "@hooks";
// utils
import {
  createStrapiContentUrl,
  createElementTypeByLocation,
  createColorWithThemeByLocation,
} from "@utils/content.utils";
// types
import { LinkType } from "@utils/types";
// styles
import {
  HeaderRoot,
  HeaderLogoNav,
  HeaderLinks,
  HeaderNav,
  HeaderLogo,
  HeaderBlock,
  RightBlock,
  StyledLink,
  StyledButton,
  BurgerButton,
} from "./style";

const Header = ({
  data,
  showOnlyLogo,
  isDefaultSticky,
  hoverColor,
  buttonColor,
}) => {
  // state
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  // hooks
  const logosData = useLogosData();
  const { sticky, headerRef, fixedRef } = useSticky();
  const { isLaptop, isMobile } = useWindowSize();
  // memo
  const mainLogo = useMemo(
    () => createStrapiContentUrl(logosData.main_logo.url),
    [logosData.main_logo]
  );
  const isSticky = useMemo(
    () => (isDefaultSticky && !isBurgerOpen) || (sticky && !isBurgerOpen),
    [sticky, isBurgerOpen, isDefaultSticky]
  );
  const burgerLinks = useMemo(() => {
    let links = [];

    data?.left_links.forEach((link) => {
      links.push(link);
    });

    data?.right_links.forEach((link) => {
      links.push(link);
    });

    return links;
  }, [data?.left_links, data?.right_links]);

  const logoWidth = useMemo(() => {
    if (showOnlyLogo) {
      if (isLaptop) return "322px";
      if (isMobile) return "250px";

      return "422px";
    }

    return "100%";
  }, [showOnlyLogo, isLaptop, isMobile]);

  const handleBurgerToggle = useCallback(() => {
    setIsBurgerOpen((prev) => !prev);
  }, []);

  return (
    <>
      <HeaderRoot
        $width={logoWidth}
        ref={headerRef}
        isSticky={!showOnlyLogo && sticky}
        isBurgerOpen={isBurgerOpen}
      >
        {showOnlyLogo ? (
          <HeaderLogoNav>
            <Link to="/">
              <HeaderLogo src={mainLogo} alt="Logo" />
            </Link>
          </HeaderLogoNav>
        ) : (
          <HeaderNav ref={fixedRef}>
            <HeaderLinks>
              <Link to="/">
                <HeaderLogo
                  src={
                    isSticky
                      ? createStrapiContentUrl(
                          createElementTypeByLocation(
                            logosData.candidate_logo.url,
                            logosData.client_logo.url
                          )
                        )
                      : mainLogo
                  }
                  alt="Logo"
                />
              </Link>
              <HeaderBlock>
                {data.left_links.map((link, i) => (
                  <StyledLink
                    to={link.url}
                    key={`${link.text}${i}`}
                    islast={i === data.left_links.length ? 1 : 0}
                    isSticky={isSticky}
                  >
                    {link.text}
                  </StyledLink>
                ))}
              </HeaderBlock>
            </HeaderLinks>
            <HeaderLinks>
              <>
                <RightBlock>
                  {data.right_links.map((link, i) => (
                    <StyledLink
                      to={link.url}
                      key={`${link.text}${i}`}
                      isSticky={isSticky}
                    >
                      {link.text}
                    </StyledLink>
                  ))}
                  {data?.button && (
                    <StyledButton
                      to={data.button.url}
                      $color={
                        buttonColor || createColorWithThemeByLocation("client")
                      }
                      $hover_color={
                        hoverColor ||
                        createElementTypeByLocation("#3DC7FA", "#40D4A8")
                      }
                    >
                      {data.button.text}
                    </StyledButton>
                  )}
                </RightBlock>
                <BurgerButton
                  onClick={handleBurgerToggle}
                  isActive={isBurgerOpen}
                  isSticky={isSticky}
                  bgColor="#fff"
                >
                  <span />
                  <span />
                  <span />
                </BurgerButton>
              </>
            </HeaderLinks>
          </HeaderNav>
        )}
      </HeaderRoot>
      <Burger isOpen={isBurgerOpen} links={burgerLinks} button={data?.button} />
    </>
  );
};

Header.propTypes = {
  data: PropTypes.shape({
    left_links: PropTypes.arrayOf(LinkType),
    right_links: PropTypes.arrayOf(LinkType),
    button: LinkType,
  }),
  showOnlyLogo: PropTypes.bool,
  isDefaultSticky: PropTypes.bool,
  hoverColor: PropTypes.string,
  buttonColor: PropTypes.string,
};

Header.defaultProps = { isDefaultSticky: false };

export default Header;
