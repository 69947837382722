import image1 from "@data/images/box-image/image-03-480x298.jpg";
import image2 from "@data/images/box-image/image-06-480x298.jpg";
import image3 from "@data/images/box-image/image-05-480x298.jpg";

export const data = [
  {
    id: 1,
    image: { src: image1 },
    title: "Backup and recovery ",
    category: "Cyber Security",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    path: "/",
  },
  {
    id: 2,
    image: { src: image2 },
    title: "Cloud Managed Services",
    category: "Cyber Security",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    path: "/",
  },
  {
    id: 3,
    image: { src: image3 },
    title: "IT Security & Compliance",
    category: "Cyber Security",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    path: "/",
  },
];
