import React, { useMemo } from "react";
import PropTypes from "prop-types";
// components
import Seo from "@components/seo";
// containers
import Header from "@containers/header";
import HomeIntro from "@containers/home-intro";
// layouts
import MainLayout from "@layout/main-layout";
// hooks
import { useHomeIntroData } from "@hooks";

const HomePage = ({ location }) => {
  // hooks
  const homeIntroData = useHomeIntroData();
  // memo
  const leftData = useMemo(
    () => ({
      title: homeIntroData.left_title,
      subtitle: homeIntroData.left_subtitle,
      image: homeIntroData.left_image,
      link: homeIntroData.left_link,
    }),
    [homeIntroData]
  );

  const rightData = useMemo(
    () => ({
      title: homeIntroData.right_title,
      subtitle: homeIntroData.right_subtitle,
      image: homeIntroData.right_image,
      link: homeIntroData.right_link,
    }),
    [homeIntroData]
  );

  return (
    <MainLayout location={location} withFooter={false}>
      <Seo />
      <Header showOnlyLogo />
      <HomeIntro leftData={leftData} rightData={rightData} />
    </MainLayout>
  );
};

HomePage.propTypes = {
  location: PropTypes.shape({}),
};

export default HomePage;
