import React, { useMemo } from "react";
import PropTypes from "prop-types";
import cn from "clsx";
import { format } from "date-fns";
// ui
import Image from "@ui/image";
import Button from "@ui/button";
import Text from "@ui/text";
import Anchor from "@ui/anchor";
// components
import SocialShare from "@components/social-share";
import AuthorMeta from "@components/author-meta";
import BlogMeta from "@components/blog-meta";
import Tags from "@components/tags";
// utils
import { slugify, isBrowser } from "@utils";
// types
import { ImagePropType } from "@utils/types";
// styles
import {
  BlogMedia,
  BlogThumb,
  BlogTitle,
  BlogHeaderMeta,
  BlogExcerpt,
  BlogFooter,
  BlogFooterLeft,
  BlogFooterRight,
} from "./style";

const Post = ({
  title,
  image,
  description,
  slug,
  postedAt,
  author,
  tags,
  className,
}) => {
  // memo
  const postLink = useMemo(() => `/blog/${slug}`, [slug]);

  return (
    <article className={cn(className, "blog")}>
      <BlogMedia>
        <BlogThumb>
          <Anchor path={postLink}>
            <Image src={image} alt={title} />
          </Anchor>
        </BlogThumb>
      </BlogMedia>
      <div className="blog-info">
        <header className="blog-header">
          <Tags tags={tags} mb={["18px"]} variant="secondary" />
          <BlogTitle>
            <Anchor path={postLink}>{title}</Anchor>
          </BlogTitle>
          <BlogHeaderMeta>
            {author?.name && author?.avatar && (
              <AuthorMeta
                mt="10px"
                mr="20px"
                slug={slugify(author.name)}
                name={author.name}
                image={author.avatar.url}
              />
            )}
            <BlogMeta
              mt="10px"
              mr="20px"
              text={format(new Date(postedAt), "MMMM d, yyyy")}
              icon="far fa-calendar-alt"
            />
          </BlogHeaderMeta>
        </header>
        <BlogExcerpt>
          <Text>{description}</Text>
        </BlogExcerpt>
        <BlogFooter>
          <BlogFooterLeft>
            <Button path={postLink}>Read More</Button>
          </BlogFooterLeft>
          <BlogFooterRight>
            <SocialShare
              url={isBrowser ? `${window.location.origin}${postLink}` : ""}
              title={title}
            />
          </BlogFooterRight>
        </BlogFooter>
      </div>
    </article>
  );
};

Post.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
  postedAt: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
  author: PropTypes.shape({
    name: PropTypes.string,
    avatar: ImagePropType,
  }),
  tags: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

export default Post;
