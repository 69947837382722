import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query ResourcesDataQuery {
    allStrapiCandidateResource {
      edges {
        node {
          title
          subtitle
          link_text
          link_url
        }
      }
    }
  }
`;

const useResourcesData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiCandidateResource?.edges[0]?.node;
};

export default useResourcesData;
