import * as React from "react";
import PropTypes from "prop-types";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import { headerData, footerData, ctaData } from "@constants";
import CtaArea from "@containers/cta/layout-04";
import MessageBox from "@containers/elements/message-box";

const MessageBoxPage = ({ pageContext, location }) => {
  return (
    <Layout location={location}>
      <Seo title="Message Box" />
      <Header data={headerData} />
      <PageHeader
        pageContext={pageContext}
        location={location}
        title="Message Box"
      />
      <main className="site-wrapper-reveal">
        <MessageBox />
        <CtaArea data={ctaData} />
      </main>
      <Footer data={footerData} />
    </Layout>
  );
};

MessageBoxPage.propTypes = {
  pageContext: PropTypes.shape({}),
  location: PropTypes.shape({}),
};

export default MessageBoxPage;
