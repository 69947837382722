import image1 from "@data/images/icons/icon-13-80x83.png";
import image2 from "@data/images/icons/icon-14-80x83.png";
import image3 from "@data/images/icons/icon-15-80x83.png";
import image4 from "@data/images/icons/icon-16-80x83.png";
import image5 from "@data/images/icons/icon-17-80x83.png";
import image6 from "@data/images/icons/icon-18-80x83.png";

export const data = [
  {
    id: 1,
    image: { src: image1 },
    title: "Backup and recovery ",
  },
  {
    id: 2,
    image: { src: image2 },
    title: "Cloud Managed Services",
  },
  {
    id: 3,
    image: { src: image3 },
    title: "IT Security & Compliance",
  },
  {
    id: 4,
    image: { src: image4 },
    title: "Software Development",
  },
  {
    id: 5,
    image: { src: image5 },
    title: "Managed IT Services",
  },
  {
    id: 6,
    image: { src: image6 },
    title: "IT consultancy",
  },
];
