import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query CandidateContactUsInfoDataQuery {
    allStrapiCandidateContactUsInfo {
      edges {
        node {
          page_title
          page_subtitle
          title
          description
          address
          email
          phone
          socials {
            id
            link_url
            icon_name
          }
        }
      }
    }
  }
`;

const useCandidateContactUsInfoData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiCandidateContactUsInfo?.edges[0]?.node;
};

export default useCandidateContactUsInfoData;
