import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query IndustryDescriptionDataQuery {
    allStrapiIndustryDescription {
      edges {
        node {
          first_title
          second_title
          image {
            url
          }
          link_text
          link_url
        }
      }
    }
  }
`;

const useIndustryDescriptionData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiIndustryDescription?.edges[0]?.node;
};

export default useIndustryDescriptionData;
