import React from "react";
import PropTypes from "prop-types";
import cn from "clsx";
// ui
import Text from "@ui/text";
// styles
import { BlogMetaWrap } from "./style";

const BlogMeta = ({ icon, text, className, ...restProps }) => {
  return (
    <BlogMetaWrap className={cn(className, "blog-meta")} {...restProps}>
      <div className="block-wrapper">
        <i className={icon}></i>
        <Text as="span">{text}</Text>
      </div>
    </BlogMetaWrap>
  );
};

BlogMeta.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default BlogMeta;
