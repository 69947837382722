import { send } from "@emailjs/browser";
// constants
import { FORM_STATUSES } from "../constants/form.constants";

export const sendCandidateContactUsData = async (data) => {
  try {
    const response = await send(
      process.env.EMAILJS_SERVICE_ID,
      process.env.EMAILJS_CANDIDATE_CONTACT_US_TEMPLATE_ID,
      data,
      process.env.EMAILJS_PUBLIC_KEY
    );

    if (response?.status === 200) {
      return {
        status: FORM_STATUSES.SUCCESS,
        message: "Data sent successfully",
      };
    } else {
      return {
        status: FORM_STATUSES.ERROR,
        message: "Something went wrong",
      };
    }
  } catch (err) {
    return err;
  }
};
