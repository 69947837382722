import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query CandidateJobsIntroDataQuery {
    allStrapiCandidateJobsIntro {
      edges {
        node {
          title
          subtitle
          image {
            url
          }
        }
      }
    }
  }
`;

const useCandidateJobsIntroData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiCandidateJobsIntro?.edges[0]?.node;
};

export default useCandidateJobsIntroData;
