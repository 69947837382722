import React, { useEffect } from "react";
import PropTypes from "prop-types";
// utils
import { createElementTypeByLocation } from "@utils/content.utils";
import { insertWidgetToDOM } from "@utils/widgets.utils";
// styles
import { theme } from "@styled/theme";

const TeamMembersWidget = ({ tag }) => {
  useEffect(() => {
    insertWidgetToDOM(
      window?.refari?.load("RefariWidget", function (widget, additionalData) {
        widget({
          node: document.getElementById("refWidget"), // 1. widget container element
          apiKey: "2806cceae3737c305e87cd5a67af9e6b63ecbc7e", // 2. agency's unique public API key
          aboutPageUrl: "https://example.com/about/", // 3. initialize widget with current property to form absolute link to About page
          defaultPage: "about", // 4. set default page 'about' for 'Job Board' widget
          themePalette: {
            // 5. theme customizations
            primary1Color: createElementTypeByLocation(
              theme.colors.candidate,
              theme.colors.primary
            ),
            textColorContent: "",
            iconColor: "",
            headingColor: "",
            textColorCategories: "",
            referralRewardColor: "",
            primary1ColorSocialize: "",
            textColorSocialize: "",
            widgetBackgroundColor: "",
            widgetTileBackgroundColor: "",
            backgroundMeetTheTeamColor: "",
            actionButton1TextColor: "",
            actionButton1BackgroundColor: "",
            actionButton1HoverColor: "",
            actionButton2TextColor: "",
            actionButton2BackgroundColor: "",
            actionButton2HoverColor: "",
            saveIconColor: "",
            linkIconColor: "",
          },
          fixedHeader: "#header", // 6. optional, selector of fixed header
          elements: {
            // 7. [optional] elements customizations
            buttonBorderRadius: 2, // Number or String (2 or '2px' or '2%')
            tileBorderRadius: 0, // Number (2)
            profileBorderRadius: 0, // Number or String (50 or '50px' or '50%')
          },
          optionalFields: ["email", "phone", "linkedin"], // 8. [optional] fields, by default shows additional info about consultant
          consultant: null, // 9. [optional] quantity consultant that will be displayed
          tags: tag ? [tag] : [""], // 10. [optional] field, ability to filter recruiters by tags on About page
          showJobsInGridMode: true, // 11. [required] field, ability to change from List view to Grid view by default need to set false
          profileMarginSeparator: "0 10px 20px", // 12. [optional] field, add additional margin space for Meet the Team page, example '2px' or '2%'
          widgetTitle: false, // 13. [optional] field, by default Title equal false if need show default text replace false on true, for show private text pass your text in this param
          showTags: true, // 14. [optional] field, by default showTags equal true, can pass next props true: for showing tags and false for hiding tags
          meetTheTeamDesign: 2, // 15. [optional] field, change display Meet the team widget (Possible values : 1 for default layout and 2 for square layout)
          displayYou: true, // 16. [optional] field, by default displayYou equals to true, it will be used to show 'You' block in the widget or not when default page is about page
          tileWidth: null, // 17. [optional] field, by default tile width equals to 288 (Min value : 208, Max value : 408) (only works when default page is about page)
          itemInARow: null, // 18. [optional] number of consultants in a single row to display. Default - 3. Available values - 1,2,3,4
          enableSlider: false, // 19. [optional] Show all the consultants in a slider or not (By default false)
          showPagination: true, // 20. [optional] will show pagination dots by default use true
          paginationStyle: 1, // 21. [optional] will show pagination arrow for value 1 and pagination dots for value 2 (by default use 1)
          overflowRight: false, // 22. [optional] will show one overflow job on right side if set to true (by default use false)
          overflowLeft: false, // 23. [optional] will show one overflow job on left side if set to true (by default use false)
          overflowFade: false, // 24. [optional] will show overflow fade behaviour on both horizontal sides (by default use false)
          autoRotate: true, // 25. [optional] will auto rotate the job slider (by default use false)
          autoRotateTimer: 5000, // 26. [optional] will add auto rotate timer in milliseconds - (by default use 5000)
          // categoryTileColor: [{486: '#ff0000'}, { 490: '#fff000' }],      // 27. [optional] field, add a tile color according to category ids, it accepts an array [{ 'category id':  color code }]
          // filterTileColor: false,                        // 28. [optional] field, unable category tile color on filters
          // offsetTop: [{360: 30}, { 991: 50 }],           // 29. [optional] field, add a top offset accept an array [{ 'screen width':  offset }]
          // baseUrl: ''                                    // 30. [required] field, set up widget base url
        });
      }),
      document.querySelector("#team-members-widget"),
      "team-members-widget"
    );
  }, [tag]);

  return (
    <div id="refWidget">
      <noscript>
        You need to enable JavaScript to run this application.
      </noscript>
    </div>
  );
};

TeamMembersWidget.propTypes = {
  tag: PropTypes.string.isRequired,
};

export default TeamMembersWidget;
