import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
  query ClientContactUsInfoDataQuery {
    allStrapiClientContactUsInfo {
      edges {
        node {
          page_title
          page_subtitle
          title
          description
          address
          email
          phone
          socials {
            id
            link_url
            icon_name
          }
        }
      }
    }
  }
`;

const useClientContactUsInfoData = () => {
  const data = useStaticQuery(query);

  return data?.allStrapiClientContactUsInfo?.edges[0]?.node;
};

export default useClientContactUsInfoData;
