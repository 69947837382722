import React from "react";
import PropTypes from "prop-types";
// ui
import Image from "@ui/image";
import Social, { SocialLink } from "@ui/social";
// types
import { SoicalType } from "@utils/types";
// styles
import { StyledAuthor, StyledAvatar, StyledInfo, StyledBio } from "./style";

const AuthorDetails = ({ avatar, name, description, socials }) => {
  return (
    <StyledAuthor>
      <StyledAvatar>
        <Image src={avatar} alt={name} />
        <Social size="small" space="8px">
          {socials.map((social) => (
            <SocialLink key={social.id} path={social.link} label={social.title}>
              <i className={social.icon}></i>
            </SocialLink>
          ))}
        </Social>
      </StyledAvatar>
      <StyledInfo>
        {" "}
        <h6>{name}</h6>
        <StyledBio>{description}</StyledBio>
      </StyledInfo>
    </StyledAuthor>
  );
};

AuthorDetails.propTypes = {
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  socials: PropTypes.arrayOf(PropTypes.shape(SoicalType)),
};

export default AuthorDetails;
